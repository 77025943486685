// import { Box, CircularProgress } from '@mui/material'
// import axios from 'axios'
// import React, { useEffect, useState } from 'react'
// import { useLocation, useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'

// const API_URL = process.env.REACT_APP_BASE_URL
// function AccountsDashboard() {
//   const token = localStorage.getItem("token")
//   const location = useLocation();
  
//   // Access the state from the location object
//   const { worksapceid, viewid } = location.state || {};

//   const fullURL = window.location.href
//   const parsedURL = new URL(fullURL)
//   // const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '')
//   const hostName = 'demo.rcorintelliger.co'

//   // const { worksapceid, viewid } = useParams();

//   const [url, setUrl] = useState()

//   useEffect(() => {
//     if (token && API_URL && worksapceid && viewid) {
//       getUrl()
//     }
//   }, [token, API_URL, worksapceid, viewid])
//   const getUrl = async () => {
//     console.log(worksapceid, viewid);

//     try {
//       const response = await axios.get(`${API_URL}/Zoho/GetViewURL/${worksapceid}/${viewid}`, {
//         headers: {
//           'Authorization': token,
//           'Content-Type': 'application/json',
//           'Authority': hostName,
//         }
//       })
//       console.log(response);
//       setUrl(response?.data?.data)
//     }
//     catch (error) {
//       // apiCallProgress(false)
//       if (error.response?.data?.message) {
//         toast.error(error?.response?.data?.message, {
//           autoClose: 5000, // Close the toast after 5 seconds
//         });
//       }
//       else {
//         toast.error(error?.message, {
//           autoClose: 5000, // Close the toast after 5 seconds
//         });
//       }
//     }
//   }

//   // Utility function to check if URL is valid
//   function isValidUrl(string) {
//     try {
//       new URL(string);
//       return true;
//     } catch (_) {
//       return false;
//     }
//   }

//   return (
//     <Box mt={2}>
//       {url ?
//         <>{isValidUrl(url) ?
//           <iframe
//             src={url}
//             title="Analytics"
//             width="100%"
//             height="800"
//             style={{ border: 'none' }}
//           /> :
//           <>
//             <Box
//               sx={{
//                 textAlign: "center",
//                 marginTop: "70px",
//               }}
//             >
//               <span>{url}</span>
//             </Box>
//           </>}
//         </>
//         :
//         <Box
//           sx={{
//             textAlign: "center",
//             marginTop: "70px",
//           }}
//         >
//           <CircularProgress />
//         </Box>
//       }

//     </Box>
//   )
// }

// export default AccountsDashboard



import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_BASE_URL;

function AccountsDashboard() {
  const token = localStorage.getItem("token");
  const location = useLocation();

  // Access the state from the location object
  const { worksapceid, viewid } = location.state || {};
  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);
  // const hostName = 'demo.rcorintelliger.co';
  const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '')
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    if (token && API_URL && worksapceid && viewid) {
      getUrl();
    }
  }, [token, API_URL, worksapceid, viewid]);

  const getUrl = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get(`${API_URL}/Zoho/GetViewURL/${worksapceid}/${viewid}`, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
          'Authority': hostName,
        }
      });
      console.log(response);
      
      setUrl(response?.data?.data);
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message, { autoClose: 5000 });
      } else {
        toast.error(error.message, { autoClose: 5000 });
      }
    } finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };

  // Utility function to check if URL is valid
  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  return (
    <Box mt={2}>
      {loading ? ( // Show loader while loading
        <Box sx={{ textAlign: 'center', marginTop: '70px' }}>
          <CircularProgress />
        </Box>
      ) : (
        url ? (
          isValidUrl(url) ? (
            <iframe
              src={url}
              title="Analytics"
              width="100%"
              height="800"
              style={{ border: 'none' }}
            />
          ) : (
            <Box sx={{ textAlign: 'center', marginTop: '70px' }}>
              <span>{url}</span>
            </Box>
          )
        ) : null
      )}
    </Box>
  );
}

export default AccountsDashboard;
