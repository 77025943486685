import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { deleteDatasource, getCategory, getConnectors, getDatasorces, getDatasorcesById } from '../api/connectorApi';
import { handleApiErrors } from '../api/HandleApiErrors';
import { useSignalAbort } from '../api/useSignalAbort';
import Connectors from './Connectors';
import DynamicForm from './DynamicForm';
// import { successToast } from '../../../HelperFile';
import ConnectorModal from './ConnectorModal';
import { useNavigate } from 'react-router-dom';
import TallyDatasource from '../tallyDatasource/TallyDatasource';

function DatasourceTable({ id, onBoarding }) {
    const abortConstants = useSignalAbort()
    const navigate = useNavigate();
    const [isConnectors, setIsConnectors] = useState(false)
    const [datasorces, setDatasorces] = useState()
    const [datasourceDetails, setDatasourceDetails] = useState()
    const [selectedDatasource, setSelectedDatasource] = useState()
    const [isLoading, setIsLoading] = useState({})
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (id) {
            getAllDataSource(id);
        }
    }, [id]);

    //connectors
    const getAllDataSource = async (id) => {
        try {
            const result = await getDatasorces(id, abortConstants.controllerConfig);
            console.log(result);
            if (result?.data?.DataSourceConnection?.length === 0) {
                setIsConnectors(true)
            }
            setDatasorces(result?.data?.DataSourceConnection)
            setSelectedDatasource(result?.data?.DataSourceConnection[0])
            getDatasourceById(result?.data?.DataSourceConnection[0]?.ConnectionId)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    //connectors
    const getDatasourceById = async (id) => {
        try {
            const result = await getDatasorcesById(id, abortConstants.controllerConfig);
            console.log(result);
            setDatasourceDetails(result?.data?.DataSourceConnection)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Data Souce By Id', 'datasourcebyid_response', 'datasourcebyid_timeout', 'datasourcebyid_request', setIsLoading)
        }
    };

    // Function for deleting lead
    const removeDatasource = async (connectionId) => {
        try {
            const result = await deleteDatasource(connectionId, abortConstants.controllerConfig);
            if (result) {
                // successToast(result?.data?.message);
                setDatasourceDetails(null)
                getAllDataSource(id);
            }
        } catch (error) {
            handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
        }
    };

    const setDatasource = (datasource) => {
        console.log(datasource);
        setDatasourceDetails(null)
        setSelectedDatasource(datasource)
        getDatasourceById(datasource.ConnectionId)
        // if (datasource?.ConnectionName === 'Tally') {

        // }
        setModalShow(true)
    }

    const closePopup = (isEdit) => {
        setModalShow(false)
        setIsConnectors(false)
        if (isEdit) {
            getDatasourceById(datasourceDetails?.ConnectionId)
        } else {
            getAllDataSource(id)
        }
    }

    const refreshData = () => {
        console.log("refresh");
        
        if (isConnectors) {
            setDatasourceDetails(null)
            getAllDataSource(id)
        }
        setIsConnectors(!isConnectors)
    }

    const deleteConfirmation = (id) => {
        const confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation) {
            removeDatasource(id)
        }
    };

    const tallyClose = () => {
        setModalShow(false)
        setSelectedDatasource(null)
        // refreshData()
    }

    const closeModalPopup = (isEdit) => {
        setModalShow(false)
        // if(isEdit){
        //     getDatasourceById(datasourceDetails?.ConnectionId)
        // }else{
        //     getAllDataSource(id) 
        // }
    }

    const getStatus = (dataSource) => {
        if (dataSource.ConnectionName === 'Tally') {
            if (dataSource.ConnectionStatus === 2) {
                return 'Partially Completed'
            } else if (dataSource.ConnectionStatus === 1) {
                return 'Completed'
            } else {
                return 'Failed'
            }
        }
        else{
            if (dataSource.ConnectionStatus === 0) {
                return 'Completed'
            } else if (dataSource.ConnectionStatus === 1) {
                return 'Pending'
            }
        }
    }

    return (
        <>{!modalShow ?
            <div className='form leadform'>
                {!isConnectors && <>
                    <Row>
                        <Col xs={12} md={8}>
                            {/* <div className='data-source heading'>Data Sources</div>
                            <div className='data-source subtitle'>
                                Data source imports set up in this workspace
                            </div> */}
                        </Col>
                        <Col xs={12} md={4} className='d-flex justify-content-end'>
                            <Button className='button datasource-add' onClick={() => refreshData()}>
                                Add Data Source
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} className='p-2 d-flex justify-content-center align-items-center'>
                            {datasorces && datasorces?.length > 0 ? (
                                <Table className='table-shadow  datasource-table-width'>
                                    <thead>
                                        <tr>
                                            <th className='data-source heading'>Data Source</th>
                                            <th className='data-source heading'>Status</th>
                                            <th className='data-source heading'>Comments</th>
                                            <th className='data-source heading'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datasorces.map((datasource, index) => (
                                            <tr key={index}>
                                                <td className="table-style table-font">{datasource.ConnectionName}</td>
                                                <td className="table-style table-font">{getStatus(datasource)}</td>
                                                <td className="table-style table-font">{datasource.PhoneNumber}</td>
                                                <td className="table-style table-font">
                                                    <span className='data-source-span'><span className='common-icon data-source-operation' onClick={() => setDatasource(datasource)}>{(datasource?.ConnectionName === 'Tally' && datasource.ConnectionStatus === 1)? 'View': 'Update'}</span> &nbsp;&nbsp; <span className='common-icon data-source-operation' onClick={() => deleteConfirmation(datasource.ConnectionId)}>Delete</span></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className='ps-3 text-center mt-5 datasource datasource-subtitle'>No data sources available</div>
                            )}
                        </Col>
                    </Row>
                </>
                }
                {isConnectors && <Connectors customerId={id} closePopup={closePopup} closeModalPopup={closeModalPopup} refreshData={refreshData} datasorces={datasorces} onBoarding={onBoarding}/>}
                {onBoarding && datasorces && datasorces?.length > 0 &&
                    <div className='mt-3 d-flex justify-content-end' >
                        <Button className='button datasource-add' onClick={() => navigate('/')}>
                            Go to home
                        </Button>
                    </div>}
            </div>
            :
            <div className='mt-5'>
                {datasourceDetails ?
                    <>
                        {selectedDatasource?.ConnectionName === 'Tally' ?
                            <TallyDatasource tallyClose={tallyClose} selectedConnector={selectedDatasource} isEdit={true} isOnBoarding={onBoarding}/>
                            :
                            <ConnectorModal selectedConnector={datasourceDetails} id={id} closePopup={closePopup} isEdit={true} connectionName='ConnectionName' closeModalPopup={closeModalPopup} />
                        }
                    </>
                    :
                    <div className='mt-5 text-center'>
                        <Spinner animation='border' />
                    </div>
                }
            </div>
        }</>
    );
}

export default DatasourceTable;
