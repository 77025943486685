import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

function AllowPermission({setIsComplete, decline}) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-2 ">
            <span className='dynamic-form-headline connector-form-label bold mb-5'>Permission Request</span>
            {/* <img
                src={`https://rcorintelliger.co:8021${connectorLogo}`}
                className="connector-img mb-4" // Added img-fluid for responsiveness
                alt=""
                height={40}
            /> */}
            <span className='dynamic-form-headline mb-5'>Do you grant RCOR Intelliger permission to access your Facebook data?</span>
            {/* <Button className='button save successpopup-button' onClick={()=>back()}>Done</Button> */}
            <div className='successpopup-button-div mb-5' >
                <Row className='w-100'>
                    <Col xs={12} md={6} className='mb-3 mb-md-0'>
                    <Button className='allow-permission-button w-100' onClick={()=>decline()}>Decline</Button>
                    </Col>
                    <Col xs={12} md={6}>
                    <Button className='button save w-100' onClick={()=>setIsComplete(true)}>Allow access</Button>
                    </Col>
                </Row>
            </div>
            {/* <div className='text-center mt-5'>
            For further information, you may refer to our security policies here.
            </div> */}
        </div>
    )
}

export default AllowPermission