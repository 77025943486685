import React, { useEffect } from 'react'
import { Box } from '@mui/material';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';


function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
}

// function generateRandom() {
//   var length = 8,
//     charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
//     retVal = "";
//   for (var i = 0, n = charset.length; i < length; ++i) {
//     retVal += charset.charAt(Math.floor(Math.random() * n));
//   }
//   return retVal;
// }

function PermissionTable({subMenu, handleChange}) {
  let actionArray = []
  let actionName = []

  // useEffect(()=>{

  // }, [subMenu])
  let menu = [...new Map(subMenu?.map(item =>
    [item['subMenu_id'], item])).values()].sort((a, b) => a.subMenu_order - b.subMenu_order)

  subMenu?.forEach(element => {
      (!actionName.includes(element['actions_name'])) && actionName.push(element['actions_name'])
  });

  menu?.forEach((item, index) =>{
    let v_item =subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'View'))
    let c_item =subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'Create'))
    let e_item =subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'Edit'))
    let d_item = subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'Delete'))
    const uniqueId = v_item?.actions_id || c_item?.actions_id || e_item?.actions_id || d_item?.actions_id || `row-${index}-${Date.now()}`;

    actionArray.push({
      id: uniqueId,  // Ensure each row has a unique ID
      menu: item.subMenu_name,
      view_id: v_item ? v_item.actions_id : null,
      create_id: c_item ? c_item.actions_id : null,
      edit_id: e_item ? e_item.actions_id : null,
      delete_id: d_item ? d_item.actions_id : null
    });
  })
  const columns = [
    {
      field: 'menu',
      headerName: '',
      flex:2 ,
    },
    {
      field: 'View',
      flex:1,
      renderHeader: () => (
       actionName.includes('View')? <span>View</span>: ''
      ),
      renderCell: (params) => (
        params.row.view_id ?
        <Checkbox onChange={(e) => { handleChange(params.row.view_id) }}/> : '' 
      ),
    },
    {
      field: 'Create',
      flex:1,
      renderHeader: () => (
        actionName.includes('Create')? <span>Create</span>: ''
      ),
      renderCell: (params) => (
        params.row.create_id ?
          <Checkbox onChange={(e) => { handleChange(params.row.create_id) }}/> : ''
      ),
    },
    {
      field: 'Edit',
      flex:1,
      renderHeader: () => (
        actionName.includes('Edit')? <span>Edit</span>: ''
      ),
      renderCell: (params) => (
        params.row.edit_id ?
        <Checkbox onChange={(e) => { handleChange(params.row.edit_id) }}/> : ''
      ),
    },
    {
      field: 'Delete',
      flex:1,
      renderHeader: () => (
        actionName.includes('Delete')? <span>Delete</span>: ''
      ),
      renderCell: (params) => (
        params.row.delete_id ?
        <Checkbox onChange={(e) => { handleChange(params.row.delete_id) }}/> : ''
      ),
    }
  ];

  return (
    <Box sx={{
      "& .MuiDataGrid-root": {
        // borderColor: " rgb(90, 88, 88)",
        // fontSize: '12px',
        borderRadius: '10px',
        backgroundColor: 'white',

      },
      "& .MuiDataGrid-columnHeaders": {
        // backgroundColor: '#489C8F',
        // color: 'white',
        // fontSize: '16px'
      },
    }}>
      <DataGrid
        columnHeaderHeight={35}
        rowHeight={35}
        rows={actionArray}
        columns={columns}
        hideFooter={true}
        getRowId={(row) => row.id}
        // getRowId={(row) => row.view_id}
        // getRowId={(row) => generateRandom()}
        disableRowSelectionOnClick
        // disableColumnMenu 
        slots={{ columnMenu: CustomColumnMenu }}

      />
    </Box>
  )
}

export default PermissionTable