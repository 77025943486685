import React, { useEffect, useState } from 'react'
import InitialPage from './InitialPage'
import { useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { useSignalAbort } from '../api/useSignalAbort';
import { onBoarding } from '../api/userApi';
import { handleApiErrors } from '../api/HandleApiErrors';
import { useNavigate } from 'react-router-dom';
import NotSync from '../CustomerOnboarding/NotSync';
import CompanyProfile from '../CustomerOnboarding/CompanyProfile';
import BranchProfile from '../CustomerOnboarding/BranchProfile';
import UserProfile from '../CustomerOnboarding/UserProfile';
import { createDatasorce, getDatasorcesById, updateDatasorce } from '../api/connectorApi';
import TallyBranch from './TallyBranch';
import SyncTally from './SyncTally';
import TallyCompany from './TallyCompany';
import TallyUser from './TallyUser';
import SuccessPopup from '../datasource/SuccessPopup';
import TallySuccess from './TallySuccess';

function TallyDatasource({ tallyClose, selectedConnector, isEdit, isOnBoarding }) {
  console.log(selectedConnector, selectedConnector?.ConnectionStatus,isEdit);
  const user = useSelector(state => state.user);
  const navigate = useNavigate()
  const localToken = localStorage.getItem("token")
  const abortConstants = useSignalAbort()
  const [isLoading, setIsLoading] = useState({})
  const [currentStep, setCurrentStep] = useState(null)
  const [dataSource, setDataSource] = useState()
  const [createdDatasource, setCreatedDatasource] = useState()
  const [isAdded, setIsAdded] = useState(false)

  useEffect(() => {
    if (isEdit) {
      // getOnboarding(1)
      getDatasourceById(selectedConnector?.ConnectionId)
    }
  }, [isEdit])

  //connectors
  const getDatasourceById = async (id) => {
    try {
      const result = await getDatasorcesById(id, abortConstants.controllerConfig);
      console.log(result);
      setDataSource(result?.data?.DataSourceConnection)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Data Souce By Id', 'datasourcebyid_response', 'datasourcebyid_timeout', 'datasourcebyid_request', setIsLoading)
    }
  };

  //for adding data source
  const addConnector = async () => {
    const data = {
      ConnectionName: selectedConnector?.DataSourceName,
      DataSourceId: selectedConnector?.DataSourceId,
      CustomerId: user?.CustomerId,
      ConnectionData: '',
      ConnectionStatus: 2
    }
    console.log(data);

    try {
      const result = await createDatasorce(data, abortConstants.controllerConfig)
      console.log(result);
      if (result) {
        setCreatedDatasource(result?.data?.DataSourceConnection)
        setIsAdded(true)
        // if(onBoarding){
        getOnboarding(1)
        // }
      }
    }
    catch (error) {
      console.log(error);
      
      // if(onBoarding){
      // getOnboarding(1)
      // }
      handleApiErrors(error, 'Create Datasource', 'addconnector_response', 'addconnector_timeout', 'addconnector_request', setIsLoading)
    }
  }
  //for updating data source
  const editConnector = async (status) => {

    // console.log(data);
    // const data = { ...selectedConnector, ConnectionStatus: 1 }
    const data = {
      ...(isEdit ? selectedConnector : createdDatasource), // Conditional spread
      ConnectionStatus: 1
    };
    console.log(data, selectedConnector?.ConnectionId, createdDatasource?.ConnectionId);

    try {
      const result = await updateDatasorce(isEdit?selectedConnector?.ConnectionId:createdDatasource?.ConnectionId, data, abortConstants.controllerConfig)
      console.log(result);
      if (result) {
        // successToast(result.data.message)
        // closeModal(true)
        // if(isOnBoarding){
        if (status === 'new') {
          tallyClose()
        } else {
          if (isOnBoarding) {
            window.location.href = '/onBoarding_datasource'
          } else {
            window.location.href = '/DataSource'
          }
        }
        // }
        // else{
        //   tallyClose()
        //   navigate('/DataSource')
        // }
        // return null
      }
    }
    catch (error) {
      handleApiErrors(error, 'Update Datasource', 'editconnector_response', 'editconnector_timeout', 'editconnector_request', setIsLoading)
    }
  }

  // API call to get onboarding details
  const getOnboarding = async (data) => {
    try {
      const result = await onBoarding(data, abortConstants.controllerConfig)
      console.log(result)
      if (result && result?.data?.OnboardingProcess) {
        setCurrentStep(result.data.OnboardingProcess[0]?.Id)
      }
    }
    catch (error) {
      handleApiErrors(error, 'OnBoarding', 'boarding_response', 'boarding_timeout', 'boarding_request', setIsLoading)
    }
  }

  const getData = () => {
    getOnboarding(1)
  }

  const goToPreviousStep = () => {
    console.log(currentStep);

    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const goToNextStep = () => {
    if (currentStep >= 0) {
      setCurrentStep(currentStep + 1)
    }
  }
  console.log(currentStep);

  // Render the component based on the current step
  // const renderStepComponent = () => {
  //   switch (currentStep) {
  //     case 'SyncBranch':
  //       return <TallyBranch />;
  //     case 'SyncCompany':
  //       return <CompanyProfile />;
  //     case 'SyncUser':
  //       return <UserProfile />;
  //     default:
  //       setCurrentStep(null);
  //       return null
  //   }
  // };

  const success = () => {
    editConnector()
  }

  const addNewIntegration = () => {
    editConnector('new')
  }

  const renderStepComponent = () => {
    if (currentStep === null) {
      return <InitialPage customerId={user?.CustomerId} getData={getData} currentStep={currentStep} addConnector={addConnector} tallyClose={skip} isEdit={isEdit} connector={selectedConnector} isAdded={isAdded}/>;
    }

    switch (currentStep) {
      case 0:
        return <SyncTally getOnboardingStep={getData} tallyClose={tallyClose} setCurrentStep={setCurrentStep} />;
      case 1:
        return (
          <TallyCompany
            onBoarding={true}
            getOnboardingStep={getData}
            goToNextStep={goToNextStep}
            getData={getData}
            tallyClose={tallyClose}
            setCurrentStep={setCurrentStep}
          />
        );
      case 2:
        return (
          <TallyBranch
            onBoarding={true}
            getOnboardingStep={getData}
            goToPreviousStep={goToPreviousStep}
            getData={getData}
            setCurrentStep={setCurrentStep}
            tallyClose={tallyClose}
          />
        );
      case 3:
        return (
          <TallyUser
            getOnboardingStep={getData}
            goToPreviousStep={goToPreviousStep}
            getData={getData}
            tallyClose={tallyClose}
            onBoarding={true}
            isEdit={true}
            setCurrentStep={setCurrentStep}
            userId={user?.userid}
          />
        );

      default:
        return <TallySuccess
          connectorLogo={isEdit ? dataSource?.LogoURL : selectedConnector?.LogoURL}
          back={success}
          closeModalPopup={addNewIntegration}
          goToPreviousStep={goToPreviousStep}
          tallyClose={tallyClose}
          setCurrentStep={setCurrentStep}
        />
    }
  };

  const getDatasourcePage = () => {
    if (isOnBoarding) {
      window.location.href = '/onBoarding_datasource'
    } else {
      window.location.href = '/DataSource'
    }
  }

  const skip =()=>{
    if(isAdded){
      tallyClose('added')
    }else{
      tallyClose('not_added')
    }
  }

  return (
    <div>
      {isEdit &&selectedConnector?.ConnectionStatus===1 &&<div className='d-flex justify-content-end'>
        <Button className='button datasource-add' onClick={() => getDatasourcePage()}>
          Back
        </Button>
      </div>}
      <div className="tally-centered-container">
        {renderStepComponent()}
      </div>
    </div>
  )
}

export default TallyDatasource