import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";
import { app } from "./FirebaseConfig";


// export const requestPermissionAndSendToken = async (customerId, userToken) => {
//     // console.log("firebase setup");
//     const messaging = getMessaging(app);
//     // console.log(messaging);

//   try {
//     const permission = await Notification.requestPermission();
//     // console.log("permission of firebase  :--",permission);

//     if (permission === "granted") {
//       const token = await getToken(messaging, {
//         vapidKey: "BDh_s2fkfDi4iGrdbLuSiw1p5HUk9aOKDuhsPqRFWP3vmYdOHjzCnfMWsm_MXxGuAbC4IVPyBGrLJSOgw4bXVXg",
//         serviceWorkerRegistration: await navigator.serviceWorker.register(
//             "/firebase-messaging-sw.js"
//           ),
//       });
//       console.log("FCM Token:", token);

//       // Send token to backend
//       const result = await axios.put(`https://rcorintelliger.co:8021/Customer/RegisterToken/${customerId}`, {
//         Token: token,
//       },
//       {
//         headers: {
//           Authorization: userToken, // Pass the userToken here
//         },
//       }
//     );
//     // console.log("result is",result);
//     return true
//     } else {
//       // console.log("Notification permission denied");
//       return false
//     }
//   } catch (error) {
//     // console.error("Error getting FCM token:", error);
//     return false
//   }
// };

export const requestPermissionAndSendToken = async (customerId, userToken) => {
  const messaging = getMessaging(app);

  try {
    const permission = await Notification.requestPermission();
    console.log("Notification permission:", permission);

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BDh_s2fkfDi4iGrdbLuSiw1p5HUk9aOKDuhsPqRFWP3vmYdOHjzCnfMWsm_MXxGuAbC4IVPyBGrLJSOgw4bXVXg",
        serviceWorkerRegistration: await navigator.serviceWorker.register("/firebase-messaging-sw.js"),
      });

      console.log("FCM Token:", token);

      await axios.put(
        `https://rcorintelliger.co:8021/Customer/RegisterToken/${customerId}`,
        { Token: token },
        { headers: { Authorization: userToken } }
      );

      return true;
    } else if (permission === "default") {
      console.log("User did not choose yet.");
      return false; // Prevent unnecessary alert
    } else {
      console.log("Notification permission denied.");
      return false;
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return false;
  }
};