import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material'
import { Button, Col, Row } from 'react-bootstrap';

function SyncTally({ getOnboardingStep, tallyClose, setCurrentStep }) {
    return (
        <div className="tally-content-container">
            {/* Content area */}
            <div className="tally-content">
                <div className="d-flex flex-column justify-content-center align-items-center p-2 ">
                <img src={process.env.PUBLIC_URL + '/assets/images/rafiki.png'} alt='not found' width={150} height={100} />
                    <span className='mt-4 tally tally-headline'>Please connect your Tally data with RCOR Connector</span>
                    <span className='mt-5'> To make sure you get the most out of our platform,  </span>
                    <span className='mt-2'>please connect your Tally data to the RCOR connector.  </span>
                    <span className='mt-2'>This simple step keeps your data current and unlocks all our features for you.</span>
                    <span>
                        If your Tally data already connected, please refresh
                        <IconButton onClick={() => getOnboardingStep()}><RefreshIcon className='common-icon' /></IconButton>
                    </span>
                </div>
            </div>
            {/* Footer buttons */}
            <div className="tally-footer">
                <Row>
                    <Col xs={6} className="d-flex justify-content-start">
                        <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <Button className='successpopup-addmore-button' onClick={() => setCurrentStep(null)}>Back</Button> &nbsp;&nbsp;
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SyncTally