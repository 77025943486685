import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography, Modal } from '@mui/material';
import Header from '../CustomerOnboarding/Header';
import { Link, useNavigate } from 'react-router-dom';
import { getUserMenu } from '../api/userApi';
import { useSelector } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import { requestPermissionAndSendToken } from './firebase/FirebaseGetToken';
import Agreement from './Agreement';
import muiStyles from '../components/styles/muiStyles';
import CloseIcon from '@mui/icons-material/Close';

function NoIntegrations() {
    const userToken = localStorage.getItem("token")
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false);

    const [terms, setTerms] = useState()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const requestPermission = async () => {
        setLoading(true)
        // console.log("Requesting permission...");
        const result = await requestPermissionAndSendToken(user?.CustomerId, userToken);
        // console.log("Permission result:", result);

        if (result) {
            setLoading(false)
            navigate('/onBoarding_datasource');
        } else {
            setLoading(false)
            alert("You need to allow notifications to proceed.");
        }
    };

    const setTermsModal = (term) => {
        setTerms(term)
        handleOpen()
    }

    return (
        <Box>
            <Header />
            <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
                <Grid container spacing={1}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} display="flex" justifyContent="center">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/welcome.png'}
                                alt="not found"
                                // className="image common"
                                height={250}
                                width={250}
                            />
                            <Typography mt={3} mb={1} className='datasource datasource-heading'>
                                Welcome to RCOR Intelliger!   &nbsp;<img
                                    src={process.env.PUBLIC_URL + '/assets/images/confetti icon.png'}
                                    alt="not found"
                                    // className="image common"
                                    height={30}
                                    width={30}
                                />
                            </Typography>
                            <Typography mt={4} mb={1} className='datasource datasource-subtitle' >
                                We’re thrilled to have you onboard! <img
                                    src={process.env.PUBLIC_URL + '/assets/images/star icon.png'}
                                    alt="not found"
                                    // className="image common"
                                    height={18}
                                    width={18}
                                /> You’re now one step closer to unlocking the power of data-driven decisions  for your business.
                            </Typography>
                            <Typography mb={1} className='datasource datasource-subtitle'>
                                Before we dive in, let’s make sure your data sources are connected to unleash the full potential of your RCOR Intelliger platform.
                            </Typography>
                            <div className='mt-4 mb-4'>
                                <FormGroup >
                                   <div className='checkbox checkbox-label'>
                                   <Checkbox
                                        size='small'
                                        checked={isChecked}
                                        onChange={(e) => setIsChecked(e.target.checked)} />
                                    <span>
                                        I agree to the <span className='span_underline' onClick={() => setTermsModal('term')}>Terms & Conditions</span> and <span className='span_underline' onClick={() => setTermsModal('policy')}>Privacy Policy</span>. We value your privacy and ensure your data is secure, complying with global data protection standards
                                    </span>
                                   </div>
                                    {/* <FormControlLabel
                                        required control={<Checkbox
                                            checked={isChecked}
                                            onChange={(e) => setIsChecked(e.target.checked)}
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: 14, // Customize the icon size
                                                },
                                            }} />}
                                        label={
                                            <span>
                                                I agree to the <span className='span_underline' onClick={() => setTermsModal('term')}>Terms & Conditions</span> and <span className='span_underline' onClick={() => setTermsModal('policy')}>Privacy Policy</span>. We value your privacy and ensure your data is secure, complying with global data protection standards
                                            </span>
                                        }
                                        sx={{
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: "14px", // Adjust the font size as needed
                                            },
                                        }}
                                    /> */}
                                </FormGroup>
                            </div>
                            <Button onClick={() => isChecked ? requestPermission() : null} className={`datasource datasource-button ${isChecked ? '' : 'datasource datasource-disable-button'}`}>
                                {loading ? <span className='d-flex'>Loading...<div className="spinner"></div></span> : <span>Get Started &nbsp;<EastIcon /></span>}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={muiStyles.agreementModalStyle.root}>
                    <div className='d-flex justify-content-end'>
                        <CloseIcon fontSize='small' className='icon-cursor' onClick={handleClose}/>
                    </div>
                    <Agreement terms={terms} />
                </Box>
            </Modal>

        </Box>
    );
}

export default NoIntegrations;
