// import React from 'react'
// import Branch from '../pages/masterFiles/Branch'
// import CompanySettings from '../pages/Settings/CompanySettings/CompanySettings'

// function TallyCompany({getOnboardingStep}) {
//     const goToNextStep =()=>{
//         // getOnboardingStep()
//     }
//   return (
//     <div>
//         <CompanySettings onBoarding={true} getOnboardingStep ={getOnboardingStep} goToNextStep={goToNextStep}/>
//     </div>
//   )
// }

// export default TallyCompany

import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  CircularProgress
} from "@mui/material";
import * as CompanyApi from '../api/companyApi'
import { getAllFyr } from "../api/fyrApi";
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../redux/slices/authSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from "../api/HandleApiErrors";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

function TallyCompany({ onBoarding, getOnboardingStep, goToNextStep, tallyClose, addConnector, getData, currentStep, setCurrentStep }) {
  const token = useSelector(state => state.token)
  const userData = useSelector(state => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState({})
  const [dateFormat, setDateFormat] = useState()
  const [timeZone, setTimeZone] = useState()
  const [organizationType, setOrganizationType] = useState()
  const [language, setLanguage] = useState()
  const [country, setCountry] = useState()
  const [currency, setCurrency] = useState()
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageBase64, setSelectedImageBase64] = useState("");
  const [companyData, setCompanyData] = useState()
  const [fyrList, setFyrList] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [formErrors, setFormerrors] = useState({})

  //load DateFormat
  const getDateFormat = async (separtor) => {
    try {
      const result = await CompanyApi.getDateFormat(separtor)
      setDateFormat(result?.data?.dateformat)
      console.log(result);
    }
    catch (error) {
      console.log(error);
    }
  }
  const getTimeZone = async () => {
    try {
      const result = await CompanyApi.getTimeZone()
      setTimeZone(result?.data?.TimeZone)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getOrganizationType = async () => {
    try {
      const result = await CompanyApi.getOrganizationType()
      setOrganizationType(result?.data?.CompanyType)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getLanguage = async () => {
    try {
      const result = await CompanyApi.getLanguage()
      setLanguage(result?.data?.Language)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getCountry = async () => {
    try {
      const result = await CompanyApi.getCountry()
      setCountry(result?.data?.Country)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getCurrency = async (id) => {
    try {
      const result = await CompanyApi.getCurrency(id)
      console.log(result);
      setCurrency(result?.data?.Currency)
      setCurrencySelect(result?.data?.Currency[0]?.currencyId)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getFyr = async () => {
    try {
      const result = await getAllFyr()
      setFyrList(result?.data?.FinancialYear)
    }
    catch (error) {
      console.log(error);
    }
  }

  const getcompanyData = async () => {
    const id = userData?.companyid
    try {
      const result = await CompanyApi.getCompanyData(id)
      console.log(result);
      const data = result?.data?.Company
      console.log(`${process.env.REACT_APP_BASE_URL}${data.logo}`);
      getDateFormat(data.FormatSeparator ? data.FormatSeparator : '/')
      getCurrency(data.countryid)
      setCompanyData(data)
      setDatesepartorSelect(data.FormatSeparator ? data.FormatSeparator : '/')
      setOrganizationTypeSelect(data.companytypeid)
      setCountrySelect(data.countryid)
      // setCurrencySelect(data.currencyId)
      setTimeZoneSelect(data.timezoneid)
      setLanguageSelect(data.languageId)
      setDateFormatSelect(data.dateformatid)
      setFyrSelect(data.fiscyearid)
      // setSelectedImage(data.logo)
      setSelectedImage(data.logo ? `${process.env.REACT_APP_BASE_URL}${data.logo}` : "")
      setCommencementYear(data.CommencementYear ? dayjs(data.CommencementYear) : null)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Get company data', 'company_response', 'company_timeout', 'company_request', setIsLoading)
    }
  }

  useEffect(() => {
    getTimeZone()
    getOrganizationType()
    getLanguage()
    getCountry()
    // getCurrency()
    getFyr()
    if (userData?.companyid) {
      getcompanyData()
    }
  }, [token, userData])

  //load Select

  const [dateFormatSelect, setDateFormatSelect] = useState('');
  const [datesepartorSelect, setDatesepartorSelect] = useState('');
  const [timeZoneSelect, setTimeZoneSelect] = useState('');
  const [organizationTypeSelect, setOrganizationTypeSelect] = useState('');
  const [languageSelect, setLanguageSelect] = useState('');
  const [countrySelect, setCountrySelect] = useState('');
  const [currencySelect, setCurrencySelect] = useState('');
  const [fyrSelect, setFyrSelect] = useState('');
  const [commencementYear, setCommencementYear] = useState('')

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;

    // Use a switch statement or if-else conditions to determine which state to update
    switch (name) {
      case 'dateFormat':
        setDateFormatSelect(value);
        break;
      case 'timeZone':
        setTimeZoneSelect(value);
        break;
      case 'organizationType':
        setOrganizationTypeSelect(value);
        break;
      case 'language':
        setLanguageSelect(value);
        break;
      case 'country':
        setCountrySelect(value);
        getCurrency(value)
        break;
      case 'currency':
        setCurrencySelect(value);
        break;
      case 'fyr':
        setFyrSelect(value);
        break;
      // Add more cases for additional select dropdowns if needed
      case 'datesepartor':
        console.log(value);
        getDateFormat(value)
        setDatesepartorSelect(value);
        // setDateFormatSelect(data[0].DateFormatid)
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  };

  const changeDateFormat = () => {
    const data = dateFormat[0].DateFormatid
    console.log(dateFormat);
    setDateFormatSelect(data)
  }

  const handleChange = (e) => {
    setFormerrors({})
    setCompanyData({ ...companyData, [e.target.name]: e.target.value })
  }

  const validate = (data) => {
    const errors = {}

    // Required Field Validations
    if (!data.companyname.trim()) {
      errors.companyname = "Company Name is required.";
    }
    if (!data.countryid) {
      errors.countryid = "Country is required.";
    }
    if (!data.dateformatid) {
      errors.dateformatid = "Date Format is required.";
    }
    if (!data.fiscyearid) {
      errors.fiscyearid = "Fiscal Year is required.";
    }
    if (!data.CurrencyId) {
      errors.CurrencyId = "Currency is required.";
    }

    console.log(errors); // Debugging

    setFormerrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleSubmit = () => {
    // e.preventDefault();
    setIsSaving(true)
    let newData = {
      companyname: companyData.companyname,
      companycode: companyData.companycode,
      logo: selectedImageBase64,
      emailid: companyData.emailid,
      mobileno: companyData.mobileno,
      address: companyData.address,
      website: companyData.website,
      dateformatid: dateFormatSelect,
      timezoneId: timeZoneSelect,
      companytypeid: organizationTypeSelect,
      languageId: languageSelect,
      countryid: countrySelect,
      CurrencyId: currencySelect,
      fiscyearid: fyrSelect,
      GstNo: companyData.GstNo,
      PostalCode: companyData.PostalCode,
      CommencementYear: commencementYear ? dayjs(commencementYear).year().toString() : null
    }
    console.log(newData);

    if (validate(newData)) {
      createcompany(newData)
    } else {
      setIsSaving(false);
    }
  }

  const createcompany = async (data) => {
    console.log(data);
    try {
      const result = await CompanyApi.updateCompanyData(companyData.companyid, data)
      console.log(result);
      if (result?.data?.Company) {
        dispatch(setUser({
          user: {
            ...userData, company: {
              ...userData.company,
              ...result?.data?.Company
            }
          }
        }))
        setIsSaving(false)
        // getcompanyData(result?.data?.Company?.companyid)
        toast.success(result.data.message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
      }
      getData()
    }
    catch (error) {
      setIsSaving(false)
      console.log(error);
      handleApiErrors(error, 'Update company settings', 'company_update_response', 'company_update_timeout', 'company_update_request', setIsLoading)
    }
  }

  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`]) {
      return true
    }
    else {
      return false
    }
  }
  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
  return (
    <div className="tally-content-container">
      {/* Content area */}
      <div className="tally-content">
        {companyData &&
          <Box mt={2} sx={{ borderRadius: "5px", bgcolor: "white", }}>
            <Box >
              <Typography color="#1F77B4" fontSize={16}>
                {onBoarding ? <Typography className="form-onboarding title">Set up your organization profile</Typography> : 'Edit Company Profile'}
              </Typography>
            </Box>

            <Box>
              {/* <form onSubmit={handleSubmit}> */}
              <Box p={1} mt={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} columnSpacing={3}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                          {onBoarding && <>
                            <Grid item xs={4} className='grid center'>
                              <Typography >
                                {" "}
                                Organization Name{" "}<span className="span asterisk">*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}  display='flex' flexDirection='column'>
                              <TextField
                                fullWidth
                                required
                                size="small"
                                variant="outlined"
                                name="companyname"
                                sx={{
                                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#1F77B4",
                                  },
                                }}
                                value={companyData?.companyname}
                                onChange={handleChange}
                              />
                              {formErrors.companyname && (
                                <span className="form-error text">
                                  {formErrors.companyname}
                                </span>
                              )}
                            </Grid>
                          </>}
                          <Grid item xs={4} className='grid center'>
                            <Typography > Country <span className="span asterisk">*</span> </Typography>
                          </Grid>
                          <Grid item xs={8}  display='flex' flexDirection='column'>
                            <FormControl
                              fullWidth
                              size="small"
                              required
                              variant="outlined"
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#1F77B4",
                                },
                              }}
                            >
                              <Select
                                size="small"
                                id="demo-simple-select-filled"
                                name='country'
                                value={countrySelect}
                                onChange={handleChangeSelect}
                              >
                                {country?.map((item) => (
                                  <MenuItem key={item.countryId} value={item.countryId}>
                                    {item.countryName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formErrors.countryid && (
                                <span className="form-error text">
                                  {formErrors.countryid}
                                </span>
                              )}
                          </Grid>
                          <Grid item xs={4} className='grid center'>
                            <Typography  > Date Format <span className="span asterisk">*</span></Typography>
                          </Grid>
                          <Grid item xs={8} className='grid center'>
                            <Grid container>
                              <Grid item xs={10} display='flex' flexDirection='column'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  required
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name="dateFormat"
                                    value={dateFormatSelect}
                                    onChange={handleChangeSelect}
                                    required
                                  >
                                    {dateFormat?.map((item) => (
                                      <MenuItem key={item.DateFormatid} value={item.DateFormatid}>
                                        {item.DateFormatname}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {formErrors.dateformatid && (
                                <span className="form-error text">
                                  {formErrors.dateformatid}
                                </span>
                              )}
                              </Grid>
                              <Grid item xs={2}>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                >
                                  <Select
                                    name='datesepartor'
                                    value={datesepartorSelect}
                                    onChange={handleChangeSelect}
                                    required
                                  >

                                    <MenuItem value='.'> . </MenuItem>
                                    <MenuItem value='-'> - </MenuItem>
                                    <MenuItem value='/'> / </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={4} className='grid center'>
                            <Typography > Fiscal Year <span className="span asterisk">*</span></Typography>
                          </Grid>
                          <Grid item xs={8} display='flex' flexDirection='column'>
                            <FormControl
                              fullWidth
                              size="small"
                              required
                              variant="outlined"
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#1F77B4",
                                },
                              }}
                            >
                              <Select
                                size="small"
                                id="demo-simple-select-filled"
                                name='fyr'
                                value={fyrSelect}
                                onChange={handleChangeSelect}
                              >
                                {fyrList?.map((item) => (
                                  <MenuItem key={item.Fyrid} value={item.Fyrid}>
                                    {item.Fyrname}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formErrors.fiscyearid && (
                                <span className="form-error text">
                                  {formErrors.fiscyearid}
                                </span>
                              )}
                          </Grid>

                          <Grid item xs={4} className='grid center'>
                            <Typography  >
                              {" "}
                              Currency{" "}<span className="span asterisk">*</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={8} display='flex' flexDirection='column'>
                            <FormControl
                              fullWidth
                              size="small"
                              required
                              variant="outlined"
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#1F77B4",
                                },
                              }}
                            >
                              <Select
                                size="small"
                                id="demo-simple-select-filled"
                                name='currency'
                                value={currencySelect}
                                onChange={handleChangeSelect}
                              >
                                {currency?.map((item) => (
                                  <MenuItem key={item.currencyId} value={item.currencyId}>
                                    {item.currencyName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formErrors.CurrencyId && (
                                <span className="form-error text">
                                  {formErrors.CurrencyId}
                                </span>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* {userData?.usertypeid === 1 &&
                  <Grid container >
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="flex-end"
                      mt={2}
                    >
                      {!isSaving && <Button
                        className="btn btn-update"
                        type="submit"
                      >
                        SAVE
                      </Button>}
                      {isSaving && <Button className='btn btn-update' ><span className="btn-text">Saving</span><div className="spinner"></div>
                      </Button>}
                    </Grid>
                  </Grid>} */}
              </Box>
              {/* </form> */}
            </Box>
          </Box>
        }
      </div>
      {/* Footer buttons */}
      <div className="tally-footer">
        <Row>
          <Col xs={6} className="d-flex justify-content-start">
            <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <Button className='successpopup-addmore-button' onClick={() => setCurrentStep(null)}>Back</Button> &nbsp;&nbsp;
            {!isSaving && <Button className='button save' onClick={() => handleSubmit()}>Next</Button>}
            {isSaving && <Button className='button save'><span className="btn-text">Saving</span><div className="spinner"></div></Button>}
          </Col>
        </Row>
      </div>
    </div>


  );
}
export default TallyCompany;
