import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Modal, Row, Toast } from 'react-bootstrap'
import { getCategory, getConnectors } from '../api/connectorApi';
import { handleApiErrors } from '../api/HandleApiErrors';
import { useSignalAbort } from '../api/useSignalAbort';
import DynamicForm from './DynamicForm';
import ConnectorModal from './ConnectorModal';
import axios from 'axios';
import TallyDatasource from '../tallyDatasource/TallyDatasource';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

function Connectors({ customerId, closePopup, closeModalPopup, refreshData, datasorces, onBoarding }) {
    const abortConstants = useSignalAbort()
    const navigate = useNavigate()
    const [categories, setCategories] = useState()
    const [connectors, setConnectors] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedConnector, setSelectedConnector] = useState()
    const [isLoading, setIsLoading] = useState({})
    const [modalShow, setModalShow] = useState(false);
    const [svgContent, setSvgContent] = useState({});

    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);

    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);

    const [popupIndex, setPopupIndex] = useState(null);
    const [formPopup, setFormPopup] = useState(false)
    // Fetch the SVG content
    const fetchSVG = async (url, id) => {
        try {
            const response = await axios.get(url, { responseType: "text" });
            setSvgContent((prev) => ({ ...prev, [id]: response.data }));
        } catch (error) {
            console.error("Error fetching SVG:", error);
        }
    };

    useEffect(() => {
        // Fetch SVGs for each connector
        connectors?.forEach((connector) => {
            const url = `https://rcorintelliger.co:8021${connector.LogoURL}`;
            fetchSVG(url, connector.LogoURL);
        });
    }, [connectors]);

    useEffect(() => {
        getAllCategory()
    }, [])

    //connectors
    const getAllCategory = async () => {
        try {
            const result = await getCategory(abortConstants.controllerConfig);
            // console.log(result);
            setCategories(result?.data?.DataSourceType)
            // setSelectedCategory(result?.data?.DataSourceType[0]?.DataSourceTypeId)
            // getConnector(result?.data?.DataSourceType[0]?.DataSourceTypeId)
            setSelectedCategory(0)
            getConnector(0)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    //connectors
    const getConnector = async (id) => {
        try {
            const result = await getConnectors(id, abortConstants.controllerConfig);
            console.log(result);
            setConnectors(result?.data?.DataSource)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    const setCategory = (id) => {
        setShowA(false)
        setShowB(false)
        setSelectedCategory(id)
        getConnector(id)
    }

    const getConnectorForm = (connector, index) => {
        setSelectedConnector(connector)
        console.log(connector);
        if (connector?.ConfigSchema) {
            setPopupIndex(null); // Reset popup index
            setModalShow(true);
        } else {
            setPopupIndex(index); // Show popup below the clicked connector
        }
    }

    const handleCloseModal = (isEdit) => {
        setModalShow(false)
        closePopup(isEdit)
        // getConnector(selectedConnector?.DataSourceId)
    }

    const handleCloseModalPopup = () => {
        setModalShow(false)
        closeModalPopup()
        // getAllCategory()
    }

    const tallyClose = (status) => {
        setModalShow(false)
        setSelectedConnector(null)
        if(status === 'added'){
        refreshData()
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    // Filter connectors based on the search term
    const filteredConnectors = connectors?.filter(connector =>
        connector.DataSourceName.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>{!modalShow ?
            <div>
                {selectedConnector?.DataSourceName !== 'Tally' && <Row>
                    <Col xs={12} md={8}>
                        {/* <div className='data-source heading'>Select Connectors</div>
                        <div className='data-source subtitle'>
                            Choose your preferred data integration app
                        </div> */}
                    </Col>
                    <Col xs={12} md={4} className='d-flex justify-content-end'>
                        {datasorces && datasorces?.length > 0 && <Button className='button datasource-add' onClick={() => refreshData()}>
                            View Datasources
                        </Button>}
                    </Col>
                </Row>}
                <Row className={`${onBoarding ? 'mt-md-5' : 'mt-md-1'}`}>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={8} className='connector-div'>
                        <Row className='mt-1'>
                            <Col xs={12} md={8} className='dynamic-form-subtitle tally-span'><span>Import your data</span></Col>
                            <Col xs={12} md={4}>
                                {/* <InputGroup>
                                    <InputGroup.Text>
                                        <SearchIcon />
                                    </InputGroup.Text>
                                    <Form.Control
                                    type="text"
                                    placeholder="Search connectors..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                </InputGroup> */}
                                <Form.Control
                                    type="text"
                                    className='search-input'
                                    placeholder='Search connectors...'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={3} className='col-rightBorder '>
                                <span className='connector-form-label data-source heading'>Category</span>
                                <div className="mt-2  connectors-col">
                                    {categories && categories?.length > 0 ? <>
                                        <div className={`connector-form-label ${selectedCategory === 0 ? 'connector-form-label-color' : ''} common-icon mb-2`} onClick={() => { setPopupIndex(null); setCategory(0) }}>
                                            All
                                        </div>
                                        {categories.map((category, index) => (
                                            <div key={index} className={`connector-form-label ${category.DataSourceTypeId === selectedCategory ? 'connector-form-label-color' : ''} common-icon mb-2`} onClick={() => { setPopupIndex(null); setCategory(category.DataSourceTypeId) }}>
                                                {category.DataSourceTypeName}
                                            </div>
                                        ))}
                                    </> : (
                                        <div>No categories available</div>
                                    )}
                                </div>
                            </Col>
                            {/* {selectedCategory &&  */}
                            <Col xs={12} md={9} style={{ position: 'relative' }}>
                                <Row className='connectors-col'>
                                    {connectors && connectors?.length > 0 ? <>{
                                        connectors?.filter(connector =>
                                            connector.DataSourceName.toLowerCase().startsWith(searchTerm.toLowerCase())
                                        ).map((connector, index) => (
                                            <Col key={index} xs={6} md={3} className='mt-2'>
                                                <div className='connector-card connector-card-border common-icon' onClick={() => getConnectorForm(connector, index)}>
                                                    <img
                                                        src={`https://rcorintelliger.co:8021${connector.LogoURL}`}
                                                        className="connector-img" // Added img-fluid for responsiveness
                                                        alt=""
                                                    />
                                                    {/* {svgContent[connector.LogoURL] ? (
                                                    // Inject the inline SVG
                                                    <div
                                                        className="connector-img"
                                                        dangerouslySetInnerHTML={{ __html: svgContent[connector.LogoURL] }}
                                                    />
                                                ) : (
                                                    <span>Loading...</span>
                                                )} */}
                                                    <span className='connector-div-label text-center'>{connector.DataSourceName}</span>
                                                    {/* <Form.Check // prettier-ignore
                                                type='checkbox'
                                                id='default-checkbox'
                                            /> */}
                                                </div>
                                                {/* Popup under the connector */}
                                                {popupIndex === index && (
                                                    <div className="popup-message">
                                                        <div className='d-flex justify-content-end'>
                                                            <CloseIcon fontSize='small' className='icon-cursor' onClick={() => setPopupIndex(null)} />
                                                        </div>
                                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                                            <img
                                                                src={process.env.PUBLIC_URL + `/assets/images/lock icon.png`}
                                                                className="connector-img  mb-2" // Added img-fluid for responsiveness
                                                                alt=""
                                                            />
                                                            <span className='text-center  mb-4'>
                                                                This connector is currently not activated for your account.
                                                                To gain access, please submit a request to our team at&nbsp;
                                                                <a href="mailto:customersupport@rcor.co">
                                                                    customersupport@rcor.co
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Col>
                                        ))
                                    }
                                        <Col xs={6} md={3} className='mt-2'>
                                            <div className='connector-card connector-card-border common-icon' onClick={() => setFormPopup(true)}>
                                                <img
                                                    src={process.env.PUBLIC_URL + `/assets/images/cant find.png`}
                                                    className="connector-img" // Added img-fluid for responsiveness
                                                    alt=""
                                                />
                                                <span className='connector-form-label text-center mt-1'>
                                                    Can’t find your data source?
                                                </span>
                                            </div>
                                            {formPopup && <div className="popup-form">
                                                <Row>
                                                    <Col xs={10}>
                                                        <span className='tally-span'>Please provide some details about your data source.</span>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end'>
                                                        <CloseIcon fontSize='small' className='icon-cursor' onClick={()=>setFormPopup(false)} />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2 p-2'>
                                                    <textarea placeholder='Type here' className='custom-textarea' />
                                                </Row>
                                                <Row className='mt-2 p-2 d-flex justify-content-center'>
                                                    <Button className='button save dynamic-form-div' >
                                                        Submit
                                                    </Button>
                                                </Row>
                                            </div>}
                                            {/* <div className='datasoure-toast pt-2'>
                                                <Toast show={showA} onClose={toggleShowA}>
                                                    <Toast.Body>
                                                        <div>
                                                            <Row>
                                                                <Col xs={10}>
                                                                    <span className='tally-span'>Please provide some details about your data source.</span>
                                                                </Col>
                                                                <Col xs={2} className='d-flex justify-content-end'>
                                                                    <CloseIcon fontSize='small' className='icon-cursor' onClick={toggleShowA} />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2 p-2'>
                                                                <textarea placeholder='Type here' className='custom-textarea' />
                                                            </Row>
                                                            <Row className='mt-2 p-2 d-flex justify-content-center'>
                                                                <Button className='button save dynamic-form-div' >
                                                                    Submit
                                                                </Button>
                                                            </Row>
                                                        </div>
                                                    </Toast.Body>
                                                </Toast>
                                            </div> */}
                                        </Col>
                                    </> : (
                                        <div>
                                            <Col xs={6} md={3} className='mt-2'>
                                                <div className='connector-card connector-card-border common-icon' onClick={() => setFormPopup(true)}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/assets/images/cant find.png`}
                                                        className="connector-img" // Added img-fluid for responsiveness
                                                        alt=""
                                                    />
                                                    <span className='connector-form-label text-center mt-1'>
                                                        Can’t find your data source?
                                                    </span>
                                                </div>
                                                <div className='datasoure-toast pt-2'>
                                                    <Toast show={showB} onClose={toggleShowB} >
                                                        <Toast.Body>
                                                            <div>
                                                                <Row>
                                                                    <Col xs={10}>
                                                                        <span className='tally-span'>Please provide some details about your data source.</span>
                                                                    </Col>
                                                                    <Col xs={2} className='d-flex justify-content-end'>
                                                                        <CloseIcon fontSize='small' className='icon-cursor' onClick={toggleShowB} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mt-2 p-2'>
                                                                    <textarea placeholder='Type here' className='custom-textarea' />
                                                                </Row>
                                                                <Row className='mt-2 p-2 d-flex justify-content-center'>
                                                                    <Button className='button save dynamic-form-div' >
                                                                        Submit
                                                                    </Button>
                                                                </Row>
                                                            </div>
                                                        </Toast.Body>
                                                    </Toast>
                                                </div>
                                            </Col>
                                        </div>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        {onBoarding && datasorces && datasorces?.length === 0 &&
                            <Row>
                                <Col xs={12} className='d-flex justify-content-end'>
                                    <Button className='successpopup-addmore-button' onClick={() => navigate('/no_integration')}>Back</Button>
                                </Col>
                            </Row>}
                    </Col>
                </Row>
            </div>
            :
            <div className='mt-5'>
                {selectedConnector?.DataSourceName === 'Tally' ?
                    <TallyDatasource tallyClose={tallyClose} selectedConnector={selectedConnector} isOnBoarding={onBoarding} isEdit={false} />
                    :
                    <ConnectorModal selectedConnector={selectedConnector} id={customerId} closePopup={handleCloseModal} connectionName='DataSourceName' isEdit={false} closeModalPopup={handleCloseModalPopup} />
                }
            </div>
        }</>
    )
}

export default Connectors