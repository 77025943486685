// import React, { useEffect, useState } from 'react'
// import { useSignalAbort } from '../api/useSignalAbort';
// import { handleApiErrors } from '../api/HandleApiErrors';
// import { getCustomerLicenseKey } from '../api/connectorApi';
// import { Col, Row } from 'react-bootstrap';

// function InitialPage({ customerId }) {
//   const abortConstants = useSignalAbort()
//   const [licenseKey, setLicenseKey] = useState()
//   const [isLoading, setIsLoading] = useState({})

//   const getLicenseKey = async () => {
//     try {
//       const result = await getCustomerLicenseKey(customerId, abortConstants.controllerConfig);
//       console.log(result);
//       setLicenseKey(result?.data?.UserSubscriptions?.find(item => item.Status === 'Active')?.LicenceKey)
//     }
//     catch (error) {
//       console.log(error);
//       handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
//     }
//   };

//   useEffect(() => {
//     if (customerId) {
//       getLicenseKey()
//     }
//   }, [customerId])

//   return (
    
//     <div style={{overflowX: 'hidden'}}>
//       <Row className='mb-4'>
//         <Col xs={12}>
//         <span className='tally tally-headline mb-5'>RCOR Connector Download Page</span>
//         </Col>
//       </Row>
//       <Row>
//         <Col xs={12} md={7}>
//           <div className='d-flex flex-column pt-2'>
//             <span className='mb-3 tally tally-subtitle'>Easily connect your Tally data with RCOR Intelliger :</span>
//             <a className='tally tally-subtitle mb-5' href='https://store.rcorintelliger.co/Connector/TallyConnector.zip'>Download RCOR Connector.zip</a>
//           </div>
//         </Col>
//         <Col xs={12} md={5}>
//           <img
//             src={process.env.PUBLIC_URL + '/assets/images/desktop mockup.png'}
//             alt="not found"
//             className="w-100"
//             height={160}
//             // width={300}
//           />
//         </Col>
//       </Row>
//       <Row>
//         <Col xs={12}>
//           <div className='d-flex flex-column pt-2'>
//             <span className='tally tally-subtitle tally-span'>Your License Key : {licenseKey}</span>
//             <ul className='mt-3'>
//               <li className='tally tally-subtitle mb-1'>Download and Install the RCOR Connector on the same location where Tally is running. </li>
//               <li className='tally tally-subtitle'>Open the RCOR Connector and enter the provided license key.</li>
//             </ul>
//           </div>
//         </Col>
//       </Row>
//     </div>
//     // <div className='d-flex flex-column pt-2'>
//     //   <span className='tally tally-headline mb-5'>RCOR Connector Download Page</span>
//     //   <span className='mb-3 tally tally-subtitle'>Easily connect your Tally data with RCOR Intelliger :</span>
//     //   <a className='tally tally-subtitle mb-5' href='https://store.rcorintelliger.co/Connector/TallyConnector.zip'>Download RCOR Connector.zip</a>
//     //   <span className='tally tally-subtitle tally-span'>Your License Key : {licenseKey}</span>
//     //   <ul className='mt-3'>
//     //     <li className='tally tally-subtitle mb-1'>Download and Install the RCOR Connector on the same location where Tally is running. </li>
//     //     <li className='tally tally-subtitle'>Open the RCOR Connector and enter the provided license key.</li>
//     //   </ul>
//     // </div>
//   )
// }

// export default InitialPage


import React, { useEffect, useState } from 'react'
import { useSignalAbort } from '../api/useSignalAbort';
import { handleApiErrors } from '../api/HandleApiErrors';
import { getCustomerLicenseKey } from '../api/connectorApi';
import { Button, Col, Row } from 'react-bootstrap';

function InitialPage({ customerId, tallyClose, addConnector, getData, currentStep, isEdit, connector, isAdded }) {
  console.log(connector, isEdit, isAdded);
  
  const abortConstants = useSignalAbort()
  const [licenseKey, setLicenseKey] = useState()
  const [isLoading, setIsLoading] = useState({})

  const getLicenseKey = async () => {
    try {
      const result = await getCustomerLicenseKey(customerId, abortConstants.controllerConfig);
      console.log(result);
      setLicenseKey(result?.data?.UserSubscriptions?.find(item => item.Status === 'Active')?.LicenceKey)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
    }
  };

  useEffect(() => {
    if (customerId) {
      getLicenseKey()
    }
  }, [customerId])

  return (
    <div className="tally-content-container">
    {/* Content area */}
    <div className="tally-content">
    <div style={{overflowX: 'hidden'}}>
      <Row className='mb-4'>
        <Col xs={12}>
        <span className='tally tally-headline mb-5'>RCOR Connector Download Page</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={7}>
          <div className='d-flex flex-column pt-2'>
            <span className='mb-3 tally tally-subtitle'>Easily connect your Tally data with RCOR Intelliger :</span>
            <a className='tally tally-subtitle mb-5' href='https://store.rcorintelliger.co/Connector/TallyConnector.zip'>Download RCOR Connector.zip</a>
          </div>
        </Col>
        <Col xs={12} md={5}>
          <img
            src={process.env.PUBLIC_URL + '/assets/images/desktop mockup.png'}
            alt="not found"
            className="w-100"
            height={160}
            // width={300}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className='d-flex flex-column pt-2'>
            <span className='tally tally-subtitle tally-span'>Your License Key : {licenseKey}</span>
            <ul className='mt-3'>
              <li className='tally tally-subtitle mb-1'>Download and Install the RCOR Connector on the same location where Tally is running. </li>
              <li className='tally tally-subtitle'>Open the RCOR Connector and enter the provided license key.</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
    </div>
    {/* Footer buttons */}
   <div className="tally-footer">
      {(!isEdit || connector?.ConnectionStatus === 2) &&<Row>
        <Col xs={6} className="d-flex justify-content-start">
          <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Button className='button save' onClick={() => !isAdded && connector?.ConnectionStatus !== 2 ? addConnector() : getData()}>Next</Button>
        </Col>
      </Row>}
    </div>
  </div>
   
    // <div className='d-flex flex-column pt-2'>
    //   <span className='tally tally-headline mb-5'>RCOR Connector Download Page</span>
    //   <span className='mb-3 tally tally-subtitle'>Easily connect your Tally data with RCOR Intelliger :</span>
    //   <a className='tally tally-subtitle mb-5' href='https://store.rcorintelliger.co/Connector/TallyConnector.zip'>Download RCOR Connector.zip</a>
    //   <span className='tally tally-subtitle tally-span'>Your License Key : {licenseKey}</span>
    //   <ul className='mt-3'>
    //     <li className='tally tally-subtitle mb-1'>Download and Install the RCOR Connector on the same location where Tally is running. </li>
    //     <li className='tally tally-subtitle'>Open the RCOR Connector and enter the provided license key.</li>
    //   </ul>
    // </div>
  )
}

export default InitialPage