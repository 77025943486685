// import React from 'react'
// import Branch from '../pages/masterFiles/Branch'

// function TallyBranch({getOnboardingStep, goToNextStep, goToPreviousStep}) {
//   return (
//     <div>
//         <Branch onBoarding={true}/>
//     </div>
//   )
// }

// export default TallyBranch

import React, { useState, useEffect } from 'react'
import {
  Box,
  Modal,
  IconButton,
  Tooltip,
  Stack, Grid,
  CircularProgress, AppBar,
  Typography, Drawer, Toolbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSelector } from 'react-redux';
import * as BranchApi from '../api/branchApi'
import { useTranslation } from 'react-i18next';
import BranchForm from '../components/forms/BranchForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import muiStyles from '../components/styles/muiStyles';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CloseIcon from '@mui/icons-material/Close';
import NoData from '../components/NoData';
import { Button, Col, Row } from 'react-bootstrap';

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
}

let branchDeleteId

function TallyBranch({ onBoarding, tallyClose, addConnector, getData, currentStep, setCurrentStep, goToPreviousStep }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenmodal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [branchList, setBranchList] = useState()
  const [branchId, setBranchId] = useState()

  const token = useSelector(state => state.token)
  const menu = useSelector(state => state.menu)
  const user = useSelector(state => state.user)
  const userToken = localStorage.getItem("token")
  const operations = menu?.filter(item => item.subMenu_name === 'Branches').map(item => item.Action_CurdNo)
  // console.log(user?.CustomerId, userToken);

  const [helpOpen, setHelpOpen] = useState(false)
  const handleHelpOpen = () => {
    setHelpOpen(true)
  }
  const handleHelpClose = () => {
    setHelpOpen(false)
  }

  useEffect(() => {
    //load all branch list
    const getAllBranch = async () => {
      try {
        const result = await BranchApi.getBranch(token)
        result.data.branch?.forEach(item => {
          item.Isactive = item.Isactive === 1 ? 'Yes' : 'No'
        })
        setBranchList(result.data.branch)
      }
      catch (error) {
        // alert(error.message)
        console.log(error);
      }
    }
    getAllBranch()
  }, [open, openModal, openDeleteModal, token])

  //to pass branch id to delete modal
  const deleteModal = (id) => {
    branchDeleteId = id
    handleDeleteOpen();
  }

  //to pass row data to edit modal from datatgrid
  const editmodal = (branch_id) => {
    setBranchId(branch_id)
    handleOpenmodal()
  }

  const handleDeleteConfirmation = (id) => {
    const confirmation = window.confirm("Are you sure you want to delete the branch?");
    if (confirmation) {
      deleteBranch(id)
    } else {

    }
  };

  //for add branch modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
  };

  //for edit branch modal
  const handleOpenmodal = () => setOpenmodal(true);
  const handleClosemodal = () => {
    setOpenmodal(false)
  };

  //for delete branch modal
  const handleDeleteOpen = () => setopenDeleteModal(true);
  const handleDeleteClose = () => setopenDeleteModal(false);

  //api call to delete a branch
  const deleteBranch = async (id) => {
    try {
      const result = await BranchApi.deleteBranch(id, token)
      console.log(result);
      if (result?.data) {
        const newList = branchList.filter(item => item.branchid !== id)
        setBranchList(newList)
        toast.success(result.data.message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
      }

    }
    catch (error) {
      console.log(error);
    }
    // handleDeleteClose()

  }

  const branchCreate = (newBranch, message) => {
    handleClose()
    toast.success(message, {
      autoClose: 3000, // Close the toast after 5 seconds
    });
  };

  const branchUpdate = (newBranch, message) => {
    handleClosemodal()
    toast.success(message, {
      autoClose: 3000, // Close the toast after 5 seconds
    });
  };

  const cancelOperation = (edit) => {
    if (edit) {
      handleClosemodal()
    }
    else {
      handleClose()
    }
  };

  //---------define columns for data grid-----------------
  const allColumns = [

    {
      field: 'branchname',
      headerName: `${t('form.branchName')}`,
      flex: 0.8,
      editable: false,
      sortable: false,
    },
    {
      field: 'branchcode',
      headerName: `${t('form.branchCode')}`,
      flex: 1.5,
      editable: false,
      sortable: false,
    },
    {
      field: 'branchaddress',
      headerName: `${t('form.branchAddr')}`,
      flex: 1.3,
      editable: false,
      sortable: false,
    },
    {
      field: 'EmailId',
      headerName: `${t('form.email')}`,
      flex: 1.8,
      editable: false,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'city',
      headerName: `${t('form.city')}`,
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'website',
      headerName: `${t('form.website')}`,
      flex: 1.2,
      editable: false,
      sortable: false,
    },
    {
      field: 'zipcode',
      headerName: `${t('form.postalCode')}`,
      flex: 0.9,
      editable: false,
      sortable: false,
    },
    {
      field: 'phone',
      headerName: `${t('form.phone')}`,
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'Isactive',
      headerName: `${t('form.active')}`,
      flex: 0.7,
      editable: false,
      align: 'center',
      sortable: false,
    },

    {
      field: 'action', headerName: `${t('form.action')}`, sortable: false, flex: 1, minWidth: 110, renderCell: (params) => {
        return (
          <Stack direction='row' spacing={1}>
            {(operations?.includes(3) || onBoarding) && <Tooltip title="Edit">
              <IconButton className='icon edit' size='large' onClick={() => { editmodal(params.row.branchid) }}>
                {/* <IconButton sx={{ color: 'blue' }} size='large' onClick={() => buttonClick(`branchSave/${params.row.branchid}`)}> */}
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>}
            {(operations?.includes(4) || onBoarding) && <Tooltip title="Delete">
              <IconButton className='icon delete' size='large' onClick={() => { handleDeleteConfirmation(params.row.branchid) }}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>}
          </Stack>
        );
      }
    }
  ];

  const columns = onBoarding
    ? allColumns.filter(column => column.field === 'branchname' || column.field === 'action')
    : allColumns;
  //-----------------------END ----------------------------------------------

  return (
    <div className="tally-content-container">
      {/* Content area */}
      <div className="tally-content">
        <Box >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: '20px'
              }}>
                <span style={{ color: '#489C8F', fontSize: '18px', fontWeight: '500' }}>{onBoarding ? <Typography className="form-onboarding title"> Set up your branch</Typography> : t('form.branchDetails')}</span>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {(operations?.includes(2) || onBoarding) && branchList?.length > 0 &&
                    <Button sx={{ height: '30px', mr: 1, borderRadius: '5px' }} className='btn btn-text-style' onClick={handleOpen} variant="contained"><AddIcon />
                      {t('form.addBranch')}</Button>
                  }
                  {/* <Tooltip title='Help'><IconButton onClick={handleHelpOpen}><HelpCenterIcon color='warning' /></IconButton></Tooltip> */}
                  {/* <Button onClick={()=>requestPermissionAndSendToken(user?.CustomerId, userToken)}></Button> */}
                </Box>
              </Box>
              <Drawer
                sx={{
                  "& .MuiDrawer-paper": {
                    marginTop: '55px'
                  },
                }}
                anchor='right'
                open={helpOpen}
                onClose={handleHelpClose}
              >
                <Box sx={{ flexGrow: 1 }} >
                  <AppBar position="sticky">
                    <Toolbar>
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Help
                      </Typography>
                      <IconButton onClick={handleHelpClose}><CloseIcon /></IconButton>
                    </Toolbar>
                  </AppBar>
                </Box>
                <Box>

                </Box>
              </Drawer>
              {/* modal to add branch */}
              <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={muiStyles.modalStyle.root}>
                  <BranchForm onBranchCreated={branchCreate} onCancel={cancelOperation} onBoarding={onBoarding} />
                </Box>
              </Modal>
              {/* modal to edit branch */}
              <Modal
                open={openModal}
                // onClose={handleClosemodal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={muiStyles.modalStyle.root}>
                  <BranchForm isEdit branchId={branchId} onBranchUpdated={branchUpdate} onCancel={cancelOperation} onBoarding={onBoarding} />
                </Box>
              </Modal>

              {/* Modal for delete branch */}
              <Modal
                open={openDeleteModal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={muiStyles.modalStyle.root}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Confirm delete?
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
                    <Button sx={{ marginRight: '20px' }} type="submit" variant="contained" color="success" onClick={() => { handleDeleteClose() }}>
                      CANCEL
                    </Button>
                    <Button variant="contained" color="error" onClick={() => { deleteBranch() }}>
                      DELETE
                    </Button>
                  </div>
                </Box>
              </Modal>

              {/* </Box> */}
            </Grid>
            <Grid item xs={12}>


              {/* display all branches in data grid */}
              {branchList ?
                <>
                  {branchList?.length !== 0 ?
                    <div style={{ width: '100%' }}>
                      <DataGrid
                        sx={{
                          backgroundColor: 'white',
                          fontSize: '12px',
                          '& .MuiDataGrid-columnHeaders': {
                            color: '#489C8F',
                            fontWeight: 500,
                            fontSize: '14px'
                          },
                        }}
                        columnHeaderHeight={40}
                        rowHeight={40}
                        rows={branchList}
                        columns={columns}
                        getRowId={(row) => row.branchid}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 12,
                            },
                          },
                        }}
                        pageSizeOptions={[12]}
                        disableRowSelectionOnClick
                        slots={{ columnMenu: CustomColumnMenu }}
                      />
                    </div> :

                    <NoData name='branch' create={handleOpen} />
                  }
                </> :
                (<Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                  <CircularProgress />
                </Box>)
              }

              {/* -------End of data grid--------- */}

            </Grid>

          </Grid>
        </Box>
      </div>
      {/* Footer buttons */}
      <div className="tally-footer">
        <Row>
          <Col xs={6} className="d-flex justify-content-start">
            <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <Button className='successpopup-addmore-button' onClick={() => goToPreviousStep()}>Back</Button> &nbsp;&nbsp;
            <Button className='button save' onClick={() => getData()}>Next</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TallyBranch;