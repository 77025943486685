import React from 'react'

function Agreement({ terms }) {
    return (
        <>{terms &&
            <div>
                {terms === 'term' ?
                    <div className='d-flex flex-column tally tally-subtitle'>
                        <span className='text-center tally tally-headline mb-3'>Terms and Conditions</span>
                        <span className='mb-3 data-source heading'>Welcome to RCOR Intelliger!</span>
                        <span className='mb-2'>By using RCOR Intelliger, you agree to the following terms:</span>
                        <ol>
                            <li className='mb-2'>
                                <span className='data-source heading'>Acceptance of Use</span>
                                <ul>
                                    <li>You agree to use RCOR Intelliger solely for lawful business purposes and to abide by all applicable laws and regulations.</li>
                                </ul>
                            </li>
                            <li className='mb-2'>
                                <span className='data-source heading'>Data Usage</span>
                                <ul>
                                    <li>RCOR Intelliger accesses and processes your data solely to provide analytics, insights, and other services requested by you.</li>
                                    <li>You retain ownership of all data uploaded or connected to RCOR Intelliger.</li>
                                </ul>
                            </li>
                            <li className='mb-2'>
                                <span className='data-source heading'>Account Responsibility</span>
                                <ul>
                                    <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities carried out under your account.</li>
                                </ul>
                            </li>
                            <li className='mb-2'>
                                <span className='data-source heading'>Service Modifications</span>
                                <ul>
                                    <li>We reserve the right to update or modify our services, including features, design, and performance, to enhance your user experience.</li>
                                </ul>
                            </li>
                            <li className='mb-2'>
                                <span className='data-source heading'>Liability Disclaimer</span>
                                <ul>
                                    <li>RCOR Intelliger strives to ensure accuracy but cannot guarantee that all analytics or insights will be error-free or suitable for all purposes. Use the platform's output as an aid, not as a definitive decision-making tool.</li>
                                </ul>
                            </li>
                            <li className='mb-2'>
                                <span className='data-source heading'>Termination of Services</span>
                                <ul>
                                    <li>We reserve the right to suspend or terminate your account if misuse or violations of these terms are detected.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    :
                    <div className='d-flex flex-column tally tally-subtitle'>
                        <span className='text-center tally tally-headline mb-3'>Software License Agreement</span>
                        <span className='mb-2'>
                            This End User Software License Agreement ("Agreement") is entered into by and between RCOR Intelliger (hereinafter referred to as "Licensor") and the undersigned user or entity (hereinafter referred to as "Licensee").
                        </span>
                        <spna className='mb-2'>PREAMBLE</spna>
                        <span className='mb-2'>
                            RCOR Intelliger (hereinafter “The Software”) is a cloud-based business intelligence application for sales analysis developed for all types of trading and services organizations. This Agreement is designed to grant the Licensee a license to use the RCOR Intelliger software and cloud services under the following terms and conditions.
                        </span>
                        <ol className="nested-list">
                            <li className='mb-2'><span className='data-source heading'>GRANT OF LICENSE</span>
                                <ol className="sub-list">
                                    <li><span>License Grant:</span> Licensor grants Licensee a non-exclusive, non-transferable license to download, install or use the RCOR Intelliger software during the subscription period, for internal business purposes only. Licensee does not have the right to distribute, sublicense, or sell the software.</li>
                                    <li><span>Subscription-Based Access:</span> The software is provided on a yearly subscription or such subscription plans tailored to meet the Licensee’s needs. Licensee's access and usage rights are granted only during the active subscription period. Licensee's right to use the software will automatically terminate if the subscription is not renewed.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>LICENSE RESTRICTIONS</span>
                                <ol className="sub-list">
                                    <li><span>Restrictions:</span> Licensee is prohibited from reverse engineering, decompiling, decrypting, modifying or disassembling the software. Any attempt to modify or create derivative works from the software is strictly forbidden. Unauthorized use, reproduction, or distribution is a breach of the license terms.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>DATA ACCESS</span>
                                <ol className="sub-list">
                                    <li><span>Access:</span> To provide optimum service the Licensee will provide the Software with access to their sales information. The software is permitted to integrate with Licensee’s accounting software such as Tally, Sage, QuickBooks etc. or a Legacy system and thus access the periodic sales information of the licensee.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>INTELLECTUAL PROPERTY RIGHTS DESCRIPTION FOR SOFTWARE LICENSING</span>
                                <ol className="sub-list">
                                    <li><span>Copyright Ownership:</span> Licensor holds the exclusive copyright to the software, including the source code, object code, documentation, and accompanying materials. Unauthorized copying, transfer, sublicensing, monetization, distribution, or reproduction is strictly prohibited. Modification, removal or concealment of any product identification, copyright, proprietary, intellectual property notices or other marks is not allowed.</li>
                                    <li><span>Protection of Confidential Information:</span> The software may contain confidential and proprietary information. Licensee is required to maintain the confidentiality of this information and take all reasonable precautions to prevent unauthorized access or disclosure to any third party. On the request of the Licensor, the Licensee, must either return, delete or destroy all Confidential Information of Licensor and certify the same.</li>
                                    <li><span>Ownership of Subscriber Data:</span> All data and content generated, uploaded, or stored within the software remain the property of the Licensee. Licensor does not claim ownership of Licensee data.
                                    </li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>CRITERIA FOR TRIAL USE</span>
                                <ol className="sub-list">
                                    <li><span>Trial Period:</span> When Licensor grants access to the Software on a trial basis the Licensee may only use the Software on a temporary basis for the period specified in this Agreement. If there is no period identified, such use is limited to 30 days after the Software is made available to the Licensee. In case the Licensee fails to stop using the Software which is authorized for use by the end of the trial period, the Licensee may be invoiced for its list price and agree to pay such invoice; The Licensor in its discretion, may stop providing the services of the Licensor’s Software during the trial period at any time, at which point the Licensee will no longer have access to any related data, information, and files and must immediately cease using the Software. During the trial period the Licensor does not provide support or bear any express or implied warranty or indemnity or liability for any bugs, errors, problems or other issues.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>SUBSCRIPTION AND PAYMENT MODEL</span>
                                <ol className="sub-list">
                                    <li><span>Subscription Plans:</span> The Licensor offers different subscription plans for the Software which is tailored to meet various customer needs. These plans may vary in terms of features, usage limits, and support options. Licensee can choose the plan that best aligns with their requirements.</li>
                                    <li><span>Pricing:</span>  The cost of each subscription plan is defined by the Licensor. Pricing may be based on factors such as the number of users, the scale of usage, or the level of service included.</li>
                                    <li><span>Yearly Billing:</span> Licensee is billed on an annual basis for their chosen subscription plan. This means Licensee makes a single payment to access the software for a one-year period.</li>
                                    <li><span>Payment Methods:</span> Licensor accepts various payment methods, including Bank Transfer, Cheque, and other common payment options.</li>
                                    <li><span>Renewals:</span> Subscriptions are set to auto-renew unless Licensee chooses to cancel. Auto-renewal ensures uninterrupted access to the software.</li>
                                    <li><span>Pricing Changes:</span> Licensor reserves the right to modify subscription prices. Licensee will be reasonably notified in advance of any price changes to their subscription plan.
                                    </li>
                                    <li><span>Pro-Rated Pricing:</span> Licensee who upgrades or downgrades their subscription plan during their subscription period will be pro-rated for the difference in cost.</li>
                                    <li><span>Cancellation and Refunds:</span>  Licensee who upgrades or downgrades their subscription plan during their subscription period will be pro-rated for the difference in cost.</li>
                                    <li><span>Support and Maintenance:</span> Subscription fees often include access to customer support and updates during the active subscription period.</li>
                                    <li><span>Data Storage:</span> The subscription fees also cover all costs for data storage in the cloud server.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>MAINTANENCE</span>
                                <ol className="sub-list">
                                    <li>To perform scheduled maintenance of the Software, the Licensee may experience some disruption. Whenever reasonably practicable, the Licensor will provide the Licensee with advance notice of such maintenance. The Licensee acknowledges that occasionally, emergency maintenance may be needed without providing advance notice, during which the Licensor may temporarily suspend Licensee’s access to, and use of the Software.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>TERMINATION</span>
                                <ol className="sub-list">
                                    <li><span>Termination of Subscription:</span> The Licensor reserves the right to terminate Licensee's access to the Software for any violation of the terms of this Agreement. Upon termination, Licensee must cease using the software, or be liable to be invoiced and be subject to legal action. Licensor is not liable for any damages or data loss resulting from termination.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>LIABILITY</span>
                                <ol className="sub-list">
                                    <li><span>Limitation of liability:</span> The Licensor is not liable for any direct or indirect damages, including but not limited to lost profits, data loss, or business interruption or loss; indirect, incidental, exemplary, special or consequential damages; loss or corruption of data; or loss of revenues, profits, goodwill or anticipated sales or savings arising from the use of the Software.
                                    </li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>WARRANTY</span>
                                <ol className="sub-list">
                                    <li>Licensor provides the Software "as is" and makes no warranties, either express or implied, regarding its fitness for a particular purpose or its performance. The licensee must acknowledge and accept any associated risks.</li>
                                </ol>
                            </li>

                            <li className='mb-2'><span className='data-source heading'>UPDATES</span>
                                <ol className="sub-list">
                                    <li><span> Automatic Updates:</span> The Licensor releases new updates, features, or improvements for the Software, which will be made available to active subscribers automatically.</li>
                                    <li><span>Access to New Features:</span> Updates may include new features, functionalities, and enhancements to the Software.</li>
                                    <li><span>Bug Fixes and Performance Improvements:</span> Updates may include bug fixes, security patches, and optimizations to ensure the Software runs smoothly.</li>
                                    <li><span>Additional Costs:</span> Updates are included in the subscription, and there are no additional charges.</li>
                                    <li><span>Security and Compatibility:</span> Updates often include security enhancements to protect data and ensure compatibility with new technologies.</li>
                                    <li><span>Transparency:</span> Licensor will notify Licensee when updates are available and provide information about the changes and improvements in each update.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>GOVERNING LAW AND JURISDICTION</span>
                                <ol className="sub-list">
                                    <li><span>Governing Law:</span> This Agreement is governed by the laws of India.</li>
                                    <li><span>Jurisdiction:</span> Any dispute under this Agreement will be resolved in the courts of [Specify the Jurisdiction within India].</li>
                                    <li><span>Arbitration:</span> All disputes at the first instance arising under or in relation to this agreement may be referred to arbitration before a sole arbitrator. If the parties fail to agree on the appointment of a sole arbitrator within the time stipulated under the Arbitration and Conciliation Act, 1996 (the “Act”) the Parties shall approach the competent Court under the Act for appointment of the Sole Arbitrator. The Arbitration proceedings shall be carried out in accordance with the Act and the Rules framed there under and the place of Arbitration shall be [specify place]. The arbitration proceedings shall be conducted in English.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>FORCE MAJEURE</span>
                                <ol className="sub-list">
                                    <li>Except for payment obligations, neither party will be responsible for failure to perform its obligations due to an event or circumstance beyond its reasonable control.</li>
                                </ol>
                            </li>
                            <li>NO WAIVER
                                <ol className="sub-list">
                                    <li>Failure by either party to enforce any right under this Agreement will not waive that right.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>SEVERABILITY</span>
                                <ol className="sub-list">
                                    <li>If any portion of this Agreement is not enforceable, it will not affect any other terms.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>ENTIRE AGREEMENT</span>
                                <ol className="sub-list">
                                    <li>This Agreement constitutes the entire understanding between the Licensor and Licensee regarding the subject matter of this Agreement superseding any prior agreements, contemporaneous communications or understandings (whether written or oral).</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>SOFTWARE AVAILABILITY</span>
                                <ol className="sub-list">
                                    <li><span>Uptime Guarantee:</span> The Licensor guarantees a 99.9% uptime for the service. In case of downtime, the Licensee will be compensated as per the terms outlined.</li>
                                    <li><span>Support Response Times:</span> Critical issues will be responded to within 1 hour, major issues within 4 hours, and minor issues within 24 hours.</li>
                                    <li><span>Service Availability:</span> Support and maintenance services are available 24/7.</li>
                                    <li><span>Escalation Procedures:</span> Unresolved issues will be escalated to higher support levels within specified timelines.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>DATA PRIVACY AND SECURITY</span>
                                <ol className="sub-list">
                                    <li><span>Data Encryption:</span> All data in transit and at rest will be encrypted using industry-standard encryption methods.</li>
                                    <li><span>Data Backup:</span> Data backups will be performed daily, with a retention period of 30 days.</li>
                                    <li><span>Compliance:</span> The software and services comply with relevant data protection regulations, including GDPR and CCPA.</li>
                                    <li><span>Data Breach Notification:</span> The Licensee will be notified within 72 hours in the event of a data breach.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>PERFORMANCE METRICS</span>
                                <ol className="sub-list">
                                    <li><span>Key Performance Indicators (KPIs):</span> Performance will be measured using KPIs such as response time, uptime, and user satisfaction.</li>
                                    <li><span>Monitoring and Reporting:</span> Performance will be continuously monitored, and monthly reports will be provided to the Licensee.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>DISASTER RECOVERY AND BUSINESS CONTINUITY</span>
                                <ol className="sub-list">
                                    <li><span>Disaster Recovery Plan:</span> The recovery point objective (RPO) is 4 hours, and the recovery time objective (RTO) is 24 hours.</li>
                                    <li><span>Business Continuity:</span> Measures are in place to ensure business continuity in case of major disruptions.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>USER MANAGEMENT AND ACCESS CONTROL</span>
                                <ol className="sub-list">
                                    <li><span>User Roles and Permissions:</span> Different user roles and permissions will be defined and enforced within the software.</li>
                                    <li><span>Access Control Policies:</span> Policies for granting, modifying, and revoking user access will be strictly followed.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>CUSTOMIZATIONS AND INTEGRATIONS</span>
                                <ol className="sub-list">
                                    <li><span>Customization Services:</span> Customization services are available within the scope defined in the agreement.</li>
                                    <li><span>Integration Support:</span> Support for integrating the software with other systems and applications will be provided.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>AUDIT AND COMPLIANCE</span>
                                <ol className="sub-list">
                                    <li><span>Audit Rights:</span> The Licensor has the right to audit the Licensee’s use of the software to ensure compliance.</li>
                                    <li><span>Compliance Reviews:</span> Regular compliance reviews will be conducted, with associated costs borne by the Licensee.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>TERMINATION AND SUSPENSION</span>
                                <ol className="sub-list">
                                    <li><span>Termination for Convenience:</span> Either party can terminate the agreement for convenience with a 30-day notice period.</li>
                                    <li><span>Suspension of Service:</span> The Licensor can suspend the service under conditions such as non-payment or security threats.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>INDEMNIFICATION</span>
                                <ol className="sub-list">
                                    <li><span>Intellectual Property Indemnification:</span> The Licensor indemnifies the Licensee against intellectual property infringement claims.</li>
                                    <li><span>General Indemnification:</span> Mutual indemnification for losses or damages arising from breaches of the agreement.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>CHANGE MANAGEMENT</span>
                                <ol className="sub-list">
                                    <li><span>Change Requests:</span> The process for requesting and approving changes to the software or services is defined.</li>
                                    <li><span>Impact Assessment:</span> Proposed changes will be assessed for their impact on the Licensee’s operations.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>TRAINING AND ONBOARDING</span>
                                <ol className="sub-list">
                                    <li><span>Training Programs:</span> Training programs for using the software will be provided to the Licensee.</li>
                                    <li><span>Onboarding Support:</span> Onboarding support will be provided to new users.</li>
                                </ol>
                            </li>
                            <li className='mb-2'><span className='data-source heading'>RENEWAL AND TERM EXTENSIONS</span>
                                <ol className="sub-list">
                                    <li><span>Automatic Renewal:</span> Subscriptions are set to auto-renew under the conditions specified.</li>
                                    <li><span>Term Extension:</span> Terms can be extended under certain conditions such as project delays or additional feature requirements.</li>
                                </ol>
                            </li>

                        </ol>

                    </div>}
            </div>
        }</>
    )
}

export default Agreement