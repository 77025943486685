// import React from 'react'
// import UserForm from '../components/forms/UserForm'
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';

// function TallyUser({getOnboardingStep, goToPreviousStep}) {
//     const user = useSelector(state => state.user);

//     const userUpdate = (message) => {
//         toast.success(message, {
//             autoClose: 3000, // Close the toast after 5 seconds
//         });
//         getOnboardingStep()
//     };
//     return (
//         <div>
//             <UserForm onBoarding={true} isEdit={true} userId={user?.userid} onUserUpdated={userUpdate}/>
//         </div>

//     )
// }

// export default TallyUser



import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/authSlice';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Divider, FormControl, MenuItem, Select, Tooltip,
    TextField, Typography, Modal, IconButton, ListItemText, Checkbox, OutlinedInput, InputAdornment,
    Paper, Popper
} from '@mui/material';
import * as BranchApi from '../api/branchApi'
import * as UserApi from '../api/userApi'
import { getLedgerUser } from '../api/ledgerApi';
import i18n from '../i18n';
import AntSwitch from '../components/styles/AntSwitch';
import { validateForm } from '../components/forms/handleValidation';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import BranchForm from '../components/forms/BranchForm';
import UploadImage from '../pages/Settings/CompanySettings/UploadImage';
import DateCalander from '../components/DateCalander';
import dayjs from 'dayjs';
import { dateConvert } from '../HelperFile';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { handleApiErrors } from '../api/HandleApiErrors';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSignalAbort } from '../api/useSignalAbort';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CustomPopper = (props) => {
    return <Popper {...props} placement="bottom-start" />;
};

const CustomPaper = (props) => {
    return (
        <Paper {...props}>
            {props.children}
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <Button
                    variant="contained"
                    // className="btn btn-search"
                    size="small"
                // onClick={() => setLedgerOpen(false)}
                >
                    Done
                </Button>
            </div>
        </Paper>
    );
};

function TallyUser({ isEdit, userId, onUserCreated, onUserUpdated, onCancel, onBoarding, goToPreviousStep, tallyClose, getData, setCurrentStep }) {
    const { t } = useTranslation();
    const abortConstants = useSignalAbort()
    const token = useSelector(state => state.token)
    const loggedUser = useSelector(state => state.user)
    const dispatch = useDispatch()

    const editmodal = (branchid, branchname) => {
        setBranchId(branchid);
        handleBrOpen()
    };

    const userValues = {
        firstname: "",
        lastname: "",
        address: "",
        code: "",
        usertypeid: "",
        gender: "",
        email: "",
        branches: "",
        mobilenumber: "",
        username: "",
        password: "",
        designationid: "",
        doj: "",
        bod: "",
        dor: "",
        martialstatus: "",
        createdby: "",
        // Role: "",
        CompanyId: loggedUser?.companyid,
        isactive: 1,
        ledgerGroup: '' ////////////////////////////////////
    }

    const validationRules = {
        firstname: { required: true },
        // lastname: { required: true },
        // code: { required: true },
        usertypeid: { required: true },
        // email: { required: true },
        branches: { required: true },
        mobilenumber: { isPhoneNumber: true },
        username: { required: isEdit ? false : true },
        password: { isPassword: true, required: isEdit ? false : true },
        // designationid: { required: true },
        branchid: { required: true },
        ledgerGroup: { required: true },
    };

    const [userData, setuserData] = useState(userValues)
    const [formErrors, setFormerrors] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [roleSelect, setRoleSelect] = useState('')
    const [branchSelect, setbranchSelect] = useState([]);
    const [defaultbranchSelect, setbranchdefaultSelect] = useState([]);
    const [desigSelect, setDesigSelect] = useState('')
    const [roleList, setRoleList] = useState()
    const [branchList, setBranchList] = useState()
    const [desigList, setDesigList] = useState()
    const [open, setOpen] = useState(false);
    const [openBr, setOpenBr] = useState(false);
    const [ledgergroupSelect, setLedgerGroupSelect] = useState([]);    ///////////////////////////////////
    const [ledgergroupList, setLedgerGroupList] = useState([]);    ////////////////////////////////////
    const [BranchId, setBranchId] = useState()
    const [selectedImage, setSelectedImage] = useState();
    const [selectedImageBase64, setSelectedImageBase64] = useState("");
    const [expiryDate, setExpiryDate] = useState(dayjs(new Date()).add(1, 'day'))
    const [viewPassword, setViewPassword] = useState(false)
    const [isLoading, setIsLoading] = useState({})
    const [branchesOpen, setBranchesOpen] = useState(false);


    //for add branch modal
    const handleOpen = (event) => {
        event.stopPropagation();
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };
    const handleBrOpen = () => {
        // event.stopPropagation();
        setOpenBr(true)
    };
    const handleBrClose = () => {
        setOpenBr(false)
    };
    const getRole = async () => {
        try {
            const result = await UserApi.getUserType(token)
            setRoleList(result.data.UserType)
        }
        catch (error) {
            console.log(error);
        }
    }

    const getBranch = async () => {
        try {
            const result = await BranchApi.getBranch(token)
            setBranchList(result.data.branch)
            // if(onBoarding){
            //   setbranchSelect(result.data.branch?.map(item=>item.branchid))
            // }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getDesignation = async () => {
        try {
            const result = await UserApi.getDesignation(token)
            setDesigList(result.data.designation)
        }
        catch (error) {
            console.log(error);
        }
    }
    const getLedgerGroup = async (branchid) => {
        console.log(branchid);
        try {
            const result = await getLedgerUser(branchid)
            // const ledgerName = [...new Set(   (result?.data?.LedgerGroup)     .filter((item) => item.IsReserved === 'Yes')     .map((element) => element.ReservedName) )];
            console.log("ledger", result);
            setLedgerGroupList(result?.data?.LedgerGroup.map(item => item.GroupName))
            if (!onBoarding && !isEdit) {
                setLedgerGroupSelect(result?.data?.LedgerGroup.map(item => item.GroupName))
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    //////////////////////////////////

    useEffect(() => {
        getBranch()
        getRole()
        getDesignation()
        getLedgerGroup(0)   ///////////////////////////////////////
    }, [])

    useEffect(() => {
        if (isEdit && onBoarding && branchList && ledgergroupList && roleList) {
            //to pass row data to edit modal from datatgrid
            const getUser = async () => {
                let user
                try {
                    const result = await UserApi.getUserById(userId, token)
                    console.log(result);
                    user = result?.data?.User
                    setbranchSelect(branchList?.map(item => item.branchid))
                    setLedgerGroupSelect(ledgergroupList)
                    setRoleSelect(user?.usertypeid)
                    setuserData({
                        ...userData,
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        code: user?.code,
                        email: user?.email,
                        mobilenumber: user?.mobilenumber?.substring(1),
                        username: user?.usertypename,
                        isactive: user?.isactive
                    });
                }
                catch (error) {
                    console.log(error);
                }
            }
            getUser()
        }

    }, [userId, branchList, ledgergroupList])

    useEffect(() => {
        if (isEdit && !onBoarding) {
            //to pass row data to edit modal from datatgrid
            const getUser = async () => {
                let user
                try {
                    const result = await UserApi.getUserById(userId, token)
                    console.log(result);
                    user = result?.data?.User
                    // getLedgerGroup(result?.data?.User.branches)
                    // getLedgerGroup()
                    const brArray = result?.data?.User.branches.split(",").map(Number);
                    const ledgerGroupString = result?.data?.User.ledgerGroup;
                    const ledgerArray = (ledgerGroupString && ledgerGroupString.length !== 0) ? ledgerGroupString.split(",") : []
                    setbranchSelect(brArray)
                    setLedgerGroupSelect(ledgerArray)
                    setuserData({
                        ...userData,
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        code: user?.code,
                        email: user?.email,
                        mobilenumber: user?.mobilenumber,
                        username: user?.usertypename,
                        isactive: user?.isactive
                    });
                    setSelectedImage(user.photo ? `${process.env.REACT_APP_BASE_URL}${user.photo}` : "")
                }
                catch (error) {
                    console.log(error);
                }
                // setbranchSelect(user?.branchid)
                setbranchdefaultSelect(user?.branchid)
                setDesigSelect(user?.designationid)
                setRoleSelect(user?.usertypeid)
                setExpiryDate(dayjs(user?.ExpiredOn))
                // setLedgerGroupSelect(user?.ledgergroupId)     ///////////////////////////////////////////
            }
            getUser()
        }

    }, [userId])



    const userUpdate = (message) => {
        toast.success(message, {
            autoClose: 3000,
        });
        getData()
    };

    const updateUser = async (data) => {
        console.log(userId, data);
        try {
            const result = await UserApi.updateUser(userId, data, token)
            console.log(result);
            if (result) {
                setIsSaving(false)
                if (result?.data?.User && loggedUser.userid === result?.data?.User?.userid) {
                    if (onBoarding) {
                        dispatch(setUser({
                            user: {
                                ...loggedUser,
                                firstname: result?.data?.User?.firstname,
                                branchid: result?.data?.User?.branchid,
                                branches: result?.data?.User?.branches,
                                ledgerGroup: result?.data?.User?.ledgerGroup
                            }
                        }))
                    } else {
                        dispatch(setUser({
                            user: {
                                ...loggedUser,
                                photo: result?.data?.User?.photo,
                                firstname: result?.data?.User?.firstname,
                                branchid: result?.data?.User?.branchid,
                                companyid: result?.data?.User?.companyid,
                                lastname: result?.data?.User?.lastname,
                                code: result?.data?.User?.code,
                                usertypeid: result?.data?.User?.usertypeid,
                                email: result?.data?.User?.email,
                                mobilenumber: result?.data?.User?.mobilenumber,
                                username: result?.data?.User?.username,
                                password: result?.data?.User?.password,
                                isactive: result?.data?.User?.isactive,
                                designationid: result?.data?.User?.designationid,
                                branches: result?.data?.User?.branches,
                                ExpiredOn: result?.data?.User?.ExpiredOn,
                                ledgerGroup: result?.data?.User?.ledgerGroup
                            }
                        }))
                    }
                }
                userUpdate(result?.data?.message)
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            console.log(error);
            handleApiErrors(error, 'User form', 'updateuser_response', 'updateuser_timeout', 'updateuser_request', setIsLoading)
        }
    }

    //To set input values for add branch 
    const handleChange = (e, event) => {
        setFormerrors({})
        if (e.target.name === 'mobilenumber') {
            // Allow only digits
            if (!/^\d*$/.test(e.target.value)) {
                setFormerrors({ ...formErrors, [e.target.name]: 'Please enter only digits' });
                return;
            }
        }
        setuserData({ ...userData, [e.target.name]: e.target.value },)
    }

    const handleActive = (e) => {
        setuserData({
            ...userData,
            isactive: e.target.checked ? 1 : 0
        });

        console.log("Isactive:", e.target.checked ? 1 : 0);
    }


    //form submission
    const handleSubmit = () => {
        // e.preventDefault()
        setFormerrors({})
        setIsSaving(true)
        const data = {
            "branchid": Number(defaultbranchSelect),
            "companyid": loggedUser?.companyid,
            "firstname": userData.firstname,
            "lastname": userData.lastname,
            "address": "nil",
            "code": userData.code,
            "usertypeid": onBoarding ? roleList?.find(item => item.usertypename === 'Admin')?.usertypeid : Number(roleSelect),
            "gender": "n",
            "email": userData.email,
            "mobilenumber": userData.mobilenumber,
            "username": userData.username,
            "password": userData.password,
            "isactive": userData.isactive,
            "designationid": Number(desigSelect),
            "branches": branchSelect.join(','),
            "photo": selectedImageBase64,
            "ExpiredOn": roleList?.find(item => item.usertypeid === roleSelect)?.usertypename === 'Demo User' ? dateConvert(expiryDate) : null,
            "doj": "2023-04-05 21:23:59",
            "bod": "2023-04-26 21:23:59",
            "dor": "2023-04-19 21:23:59",
            "martialstatus": "n",
            "ledgerGroup": ledgergroupSelect.join(',')
            // ...(roleList?.find(item => item.usertypeid === roleSelect)?.usertypename === 'Demo User' && { ExpiredOn: dateConvert(expiryDate) })   //////////////////////////////////
        }
        console.log(data);
        let errors = {};

        if (data.firstname === "") {
            errors.firstname = "Name is required";
        }
        if (!data.branchid || isNaN(data.branchid) || data.branchid <= 0) {
            errors.branchid = "Default branch is required";
        }
        // If errors exist, update state and prevent API call
        if (Object.keys(errors).length > 0) {
            console.log(errors);

            setFormerrors({ ...formErrors, ...errors });
            setIsSaving(false)
            return; // Stop further execution
        }
        console.log("no error");

        // No errors, proceed with update
        updateUser(data);
    }


    const handledefaultbranchSelect = (event) => {
        const value = event.target.value;
        setbranchdefaultSelect(value ? Number(value) : 0);
    };


    return (
        <div className="tally-content-container">
            {/* Content area */}
            <div className="tally-content">
                <div className="form-box" dir={i18n.dir()}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className="form-onboarding title" mb='20px'>
                                Set up your user profile
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={1} maxHeight='500px' overflow='auto'>
                                <Box>
                                    {onBoarding &&
                                        <Grid container spacing={1} columnSpacing={3}>
                                            <Grid item xs={12} md={3} className='grid center'>
                                                <Typography className='span form-text'>{t("form.firstName")}<span className="span asterisk">*</span></Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} className='' display='flex' flexDirection='column'>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    // label={t('form.UserName')}
                                                    variant="outlined"
                                                    required
                                                    name="firstname"
                                                    error={!!formErrors.firstname}
                                                    value={userData.firstname}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.firstname && (
                                                    <span style={{ color: "red" }}>
                                                        {formErrors.firstname}
                                                    </span>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={3} className='' display='flex' flexDirection='column'>
                                                <Typography className='span form-text'>{t("form.Defaultbranch")}<span className="span asterisk">*</span></Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} className='grid center'>
                                                <Box className='' display='flex' flexDirection='column' width='100%'>
                                                    <FormControl required size="small" fullWidth>
                                                        <Select
                                                            size="small"
                                                            fullWidth
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                            id="demo-simple-select-filled"
                                                            name="branchid"
                                                            error={!!formErrors?.branchid}
                                                            value={defaultbranchSelect}
                                                            onChange={handledefaultbranchSelect}
                                                        >
                                                            {branchList?.map((item) => (
                                                                <MenuItem
                                                                    key={item.branchid}
                                                                    name={item.branchname}
                                                                    value={item.branchid}
                                                                >{item.branchname}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {formErrors.branchid && (
                                                        <span style={{ color: "red" }}>
                                                            {formErrors.branchid}
                                                        </span>
                                                    )}
                                                </Box>
                                            </Grid>

                                        </Grid>}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Footer buttons */}
            <div className="tally-footer">
                <Row>
                    <Col xs={6} className="d-flex justify-content-start">
                        <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <Button className='successpopup-addmore-button' onClick={() => goToPreviousStep()}>Back</Button> &nbsp;&nbsp;
                        {!isSaving && <Button className='button save' onClick={() => handleSubmit()}>Next</Button>}
                        {isSaving && <Button className='button save'><span className="btn-text">Saving</span><div className="spinner"></div></Button>}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default TallyUser