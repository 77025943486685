import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

function TallySuccess({ connectorLogo, back, closeModalPopup, tallyClose, goToPreviousStep, setCurrentStep, onBoarding }) {
    return (
        <div className="tally-content-container">
            {/* Content area */}
            <div className="tally-content">
                <div className="d-flex flex-column justify-content-center align-items-center p-2 ">
                    <span className='dynamic-form-headline connector-form-label bold mb-4'>Integration completed!</span>
                    <img
                        src={`https://rcorintelliger.co:8021${connectorLogo}`}
                        className="mb-4" // Added img-fluid for responsiveness
                        alt=""
                        height={70}
                        width={100}
                    />
                    <span className='dynamic-form-headline mb-5'>Choose an option to proceed: Add more integrations or finish the setup.</span>
                    {/* <Button className='button save successpopup-button' onClick={()=>back()}>Done</Button> */}
                    <div className='successpopup-button-div mb-4' >
                        <Row className='w-100'>
                            <Col xs={12} md={6} className='mb-3 mb-md-0'>
                                <Button className='successpopup-addmore-button w-100' onClick={() => closeModalPopup()}>Add more integrations</Button>
                            </Col>
                            <Col xs={12} md={6}>
                                <Button className='button save w-100' onClick={() => back()}>Finish</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {/* Footer buttons */}
            <div className="tally-footer">
                <Row>
                    <Col xs={6} className="d-flex justify-content-start">
                        <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <Button className='successpopup-addmore-button' onClick={() => setCurrentStep(null)}>Back</Button> &nbsp;&nbsp;
                    </Col>
                </Row>
            </div>
        </div>

    )
}

export default TallySuccess