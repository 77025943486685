import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Modal,
  IconButton,
  Tooltip,
  Stack, Grid,
 Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSelector } from 'react-redux';
import * as UserApi from '../../api/userApi'
import ActionSetiings from '../ActionSetiings';
import muiStyles from '../../components/styles/muiStyles';


//style for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
}

let userDeleteId

function RoleSettings() {
  const token = useSelector(state => state.token)
  const menu = useSelector(state => state.menu)
  const operations = menu?.filter(item =>item.subMenu_name === 'Roles').map(item=>item.Action_CurdNo)

  const [openDeleteModal, setopenDeleteModal] = useState(false);

  const [roleList, setRoleList] = useState([])
  const [isNewRole, setIsNewRole] = useState(false)
  const [roleId, setRoleId] = useState()
  const [operation, setOperation] = useState()
 
  useEffect(() => {
      //load all role
      const getRole = async () => {
        try {
            const result = await UserApi.getUserType(token)
            console.log(result);
            const modifiedData = result.data.UserType?.map((item) => ({...item,
              isactive: item.isactive === 1? 'Yes': 'No'
          }));
            setRoleList(modifiedData)
        }
        catch (error) {
            console.log(error);
        }
    }
    getRole()
  }, [openDeleteModal , isNewRole])

  //to pass branch id to delete modal
  const deleteModal = (id) => {
    userDeleteId = id
    handleDeleteOpen();
  }

  //for delete branch modal
  const handleDeleteOpen = () => setopenDeleteModal(true);
  const handleDeleteClose = () => setopenDeleteModal(false);

  //api call to delete a branch
  const deleteRole = async () => {
    try {
      const result = await UserApi.deleteUsertype(userDeleteId, token)
      alert(result.data.message)
    }
    catch (error) {
      console.log(error);
    }
    handleDeleteClose()

  }


  //---------define columns for data grid-----------------
  const columns = [

    {
      field: 'usertypename',
      headerName: 'Role',
      flex:1,
      editable: false,
      sortable: false,
    },
    {
      field: 'isactive',
      headerName: 'Is Active',
      flex:1,
      editable: false,
      sortable: false,
    },
    {
      field: 'action', headerName: 'Actions', sortable: false, flex:1, renderCell: (params) => {
        return (
          <Stack direction='row' spacing={1}>
            {operations?.includes(3)&&<Tooltip title="Edit">
              <IconButton className='icon edit' size='large' onClick={() => {setRoleId(params.row.usertypeid); setIsNewRole(true); setOperation('edit');}}>
                <EditOutlinedIcon fontSize='small'/>
              </IconButton>
            </Tooltip>}
           {operations?.includes(4)&& <Tooltip title="Delete">
              <IconButton className='icon delete' size='large' onClick={() => { deleteModal(params.row.usertypeid) }}>
                <DeleteOutlineOutlinedIcon fontSize='small'/>
              </IconButton>
            </Tooltip>}
          </Stack>
        );
      }
    }
  ];
  //-----------------------END ----------------------------------------------

  return (
    <Box mt={2}>
     {!isNewRole && 
     <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <span style={{ color: '#489C8F', fontSize: '18px', fontWeight: '500' }}>Role Details</span>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {operations?.includes(2)&&<Button sx={{ height: '30px', mr: 1, borderRadius: '5px' }} className='btn btn-text-style'  onClick={()=>{setRoleId(0); setIsNewRole(true); setOperation('add')}} variant="contained">
                <AddIcon />Add Role</Button>}
              {/* <Tooltip title='Help'><IconButton onClick={handleHelpOpen}><HelpCenterIcon color='warning' /></IconButton></Tooltip> */}
            </Box>
          </Box>
          {/* <Box sx={{
            float: 'right'
          }}>
            <Button sx={{ height: '30px'}} onClick={()=>{setRoleId(0); setIsNewRole(true); setOperation('add')}} variant="outlined"><AddIcon />Add Role</Button>
          </Box> */}

          {/* Modal for delete branch */}
          <Modal
            open={openDeleteModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm delete?
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
                <Button sx={{ marginRight: '20px' }} type="submit" variant="contained" color="success" onClick={() => { handleDeleteClose() }}>
                  CANCEL
                </Button>
                <Button variant="contained" color="error" onClick={() => { deleteRole() }}>
                  DELETE
                </Button>
              </div>
            </Box>
          </Modal>

          {/* </Box> */}
        </Grid>
        <Grid item xs={12}>
        {roleList !== null && roleList?.length !== 0 ?
            <div style={{ width: '100%' }}>
              <Box sx={muiStyles.gridStyles.root}>
                <DataGrid
                 columnHeaderHeight={40}
                 rowHeight={38}
                rows={roleList}
                columns={columns}
                getRowId={(row) => row.usertypeid}
                sx={{
                  backgroundColor: 'white', 
                  fontSize: '12px', 
                  '& .MuiDataGrid-columnHeaders': {
                    color: '#489C8F',
                    fontWeight: 500,
                    fontSize: '14px'
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                slots={{ columnMenu: CustomColumnMenu }}
              />
              </Box>
            </div> :

            'No user to display'
          }

        </Grid>

      </Grid>
     </>
     }
     {isNewRole && <ActionSetiings setIsNewRole={setIsNewRole} roleId={roleId} operation={operation}/>}
    </Box>


  );
}

export default RoleSettings;