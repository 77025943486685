import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

function SuccessPopup({ connectorLogo, back, closeModalPopup}) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-2 ">
            <span className='dynamic-form-headline connector-form-label bold mb-4'>Integration completed!</span>
            <img
                src={`https://rcorintelliger.co:8021${connectorLogo}`}
                className="connector-img mb-4" // Added img-fluid for responsiveness
                alt=""
                height={40}
            />
            <span className='dynamic-form-headline mb-5'>Choose an option to proceed: Add more integrations or finish the setup.</span>
            {/* <Button className='button save successpopup-button' onClick={()=>back()}>Done</Button> */}
            <div className='successpopup-button-div mb-3' >
                <Row className='w-100'>
                    <Col xs={12} md={6} className='mb-3 mb-md-0'>
                    <Button className='successpopup-addmore-button w-100' onClick={()=>closeModalPopup()}>Add more integrations</Button>
                    </Col>
                    <Col xs={12} md={6}>
                    <Button className='button save w-100' onClick={()=>back()}>Finish</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SuccessPopup