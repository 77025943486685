import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
    AppBar,
    Box,
    CssBaseline,
    Menu, MenuItem,
    Drawer,
    IconButton, Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    Collapse,
    useMediaQuery,
    ListItemIcon,
    Dialog, Avatar, Badge, Modal,
    DialogTitle, DialogContent, DialogActions, Tooltip, Divider, Grid, Autocomplete, TextField
} from '@mui/material';
import ThemeProvider from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearSearchParameters, setLogout } from '../redux/slices/authSlice';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ChevronLeft } from '@mui/icons-material';
import { ChevronRight } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockResetIcon from '@mui/icons-material/LockReset';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FlexBetween from './FlexBetween';
import newLogo from '../images/logo_dark.png'
import ChangePassword from './userRole/ChangePassword';
import { setMenu } from '../redux/slices/authSlice';
import { getLastUpdateDate, getUserMenu, logout } from '../api/userApi';
import InternetStatusChecker from '../InternetStatusChecker';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PersonImg from '../Assets/Images/avatar_img.jpg'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import ScrollToTopButton from './ScrollToTopButton';
import MessageIcon from '@mui/icons-material/Message';
import RedditIcon from '@mui/icons-material/Reddit';
import muiStyles from './styles/muiStyles';
import RightChat from './RightChat';
import LeftChat from './LeftChat';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import IconDrawer from './IconDrawer';
import searchKeywords from './SearchKeyword';
import SettingsIcon from '@mui/icons-material/Settings';
import AppContext from '../AppContext';
import { getSvgpath } from '../HelperFile';
import { getMenuIcon } from './GetMenuIcon';

const drawerWidth = 210;
const iconComponents = {
    HouseOutlinedIcon,
    CreateNewFolderIcon,
    SummarizeIcon,
    BarChartIcon,
    SettingsSuggestIcon,
    MonetizationOnOutlinedIcon,
    TrendingDownOutlinedIcon,
    CompareOutlinedIcon,
    StackedBarChartOutlinedIcon
};

const initialMenuStates = {};

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    cursor: 'default',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:active': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));


function DrawerComponent({ handleLanguageChange, languageDirection }) {
    const { t } = useTranslation();
    const { isSidebarOpen, setIsSidebarOpen, isNonMobile } = useContext(AppContext)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    //for settings menu
    const [anchorElSetting, setAnchorElSetting] = useState(null);
    const handleOpenSettingMenu = (event) => {
        setAnchorElSetting(event.currentTarget);
    };
    const handleCloseSettingMenu = () => {
        setAnchorElSetting(null);
    };

    // const [language, setLanguage] = useState()
    // const isNonMobile = useMediaQuery("(min-width: 600px)");
    // const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [mainMenu, setMainMenu] = useState()
    // const [errStatus, setErrStatus] = useState(false)
    const [menuArray, setMenuArray] = useState()
    const [settingsMenu, setSettingsMenu] = useState()
    const { pathname } = useLocation();
    const [active, setActive] = useState();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    console.log(user);
    // const menuArray = useSelector(state => state.menu)
    const [syncDate, setSyncDate] = useState()

    // right chat dialog
    const [isChatOpen, setIsChatOpen] = useState(false);
    // left chat dialog
    const [isLeftPopupOpen, setIsLeftPopupOpen] = useState(false);
    //right chatbot
    const handleChatOpen = () => {
        setIsChatOpen(true);
    };

    const handleChatClose = () => {
        setIsChatOpen(false);
    };

    //left dialog box
    const handleLeftPopupOpen = () => {
        setIsLeftPopupOpen(true);
    };

    const handleLeftPopupClose = () => {
        setIsLeftPopupOpen(false);
    };

    const drawerClose = () => {
        setIsSidebarOpen(false)
    }

    const getMenu = async (userTypeId) => {
        console.log("inside menu");
        try {
            const response = await getUserMenu(userTypeId, token)
            console.log("menu", response);
            if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
                // const updatedArray = response.data.SubMenu.map(item => {
                //     // Check if the item has subMenu_route equal to 'chart_of_accounts' and update the route if true
                //     if (item.subMenu_route === 'chart_of_accounts') {
                //       return { ...item, subMenu_route: 'chart_of_accounts/:head' };
                //     }
                //     return item;
                //   });
                setMenuArray(response.data.SubMenu)
                dispatch(setMenu({ menu: response.data.SubMenu }))
                const hasDashboards = response.data.SubMenu.some(item => item.menu_name === 'Dashboards');
                // if (!hasDashboards) {
                //     window.location.href = '/no_workspace'
                //     return
                // }
                // dispatch(setSubmenuRoute({ menuRoute: updatedArray.map(item=> item.subMenu_route)}))
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (token) {
            getMenu(user.usertypeid)
        }
    }, [token])

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        handleCloseMenu()
    };
    const handleClose = () => {
        setOpen(false);
        handleCloseMenu()
    };


    const refreshPage = () => {
        window.location.reload();
    };

    const getSyncDate = useCallback(async () => {
        try {
            const response = await getLastUpdateDate(token)
            setSyncDate(response.data.LastUpdate[0]?.lastupdate);

        } catch (error) {
            console.log(error);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            getSyncDate()
        }
    }, [token, getSyncDate])

    useEffect(() => {
        if (token === null) {
            navigate('/loginpage')
        }
    }, [token, navigate])

    useEffect(() => {
        setMainMenu([...new Map(menuArray?.map(item =>
            [item['menu_id'], item])).values()].sort((a, b) => a.menu_order - b.menu_order));
        menuArray?.forEach(item => {
            const stateName = "isOpenMenu" + item.menu_id;
            initialMenuStates[stateName] = true;
        });
        setActive(menuArray?.find(item => item.isDashboar === 1 && item.subMenu_order === 1)?.subMenu_id)
        const filteredArray = menuArray?.filter(element => element.menu_name === "Settings")
            .filter(item => item.subMenu_name !== "Events");

        const uniqueFilteredArray = Array.from(new Set(filteredArray?.map(item => item.subMenu_id))).map(subMenu_id => {
            return filteredArray.find(item => item.subMenu_id === subMenu_id);
        });
        console.log(uniqueFilteredArray);
        setSettingsMenu(uniqueFilteredArray)
    }, [menuArray])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const [menuStates, setMenuStates] = useState(initialMenuStates);

    // Function to toggle the state of a menu item
    const toggleMenu = (menu_id) => {
        const updatedObject = { ...menuStates };
        const stateName = "isOpenMenu" + menu_id;
        const keysToExclude = [stateName];
        Object.keys(updatedObject).forEach((key) => {
            if (keysToExclude.includes(key)) {
                updatedObject[key] = !updatedObject[key]
            }
            else {
                updatedObject[key] = false;
            }
        });
        setMenuStates(updatedObject)
    };

    const handleSubMenu = (menu_id) => {
        let open = menuStates["isOpenMenu" + menu_id];
        return open
    };

    const setIcon = (ic) => {
        const IconComponent = iconComponents[ic];
        if (IconComponent) {
            return <IconComponent />;
        }
        else {
            return <HouseOutlinedIcon />
        }
    };

    // useEffect(() => {
    //     setActive(pathname.substring(1));
    // }, [pathname]);

    const logOut = () => {
        handleCloseMenu()
        serverLogout(user.userid)
        localStorage.removeItem("token")
        localStorage.clear()
        dispatch(setLogout())
        // setTimeout(() => {
        //     window.location.href = '/loginpage'
        // }, 3000)
    }

    const serverLogout = async (id) => {
        console.log(id);
        try {
            const response = await logout(id)
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    const languageChange = (lng) => {
        handleLanguageChange(lng)
        handleCloseUserMenu()
    }

    const handleLogoutConfirmation = () => {
        handleCloseMenu();
        const confirmation = window.confirm("Are you sure you want to logout?");
        if (confirmation) {
            logOut()
        } else {

        }
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        console.log(event.target.value);
        setInputValue(event.target.value);
    };
    const handleOptionSelect = (event, value) => {
        console.log("inside search", value);
        if (value) {
            // Navigate to the selected route
            const selectedKeyword = searchKeywords.find(keyword => keyword.keyword === value);
            if (selectedKeyword) {
                navigate(`/${selectedKeyword.route}`, {
                    state: { scrollToWidget: selectedKeyword.widgetId } // Pass widgetId as state
                });
                setInputValue('')
            }
        }
    };

    const removeSearchDetails = (menuName) => {
        console.log(menuName);
        if (menuName === 'DashBoards') {
            dispatch(clearSearchParameters())
        }
    }

    const onMenuClick = (menu) => {
        setActive(menu.subMenu_id)
        if (menu.Source_Type === 1) {
            // window.location.href = `dashboard${menu.subMenu_route}`
            // Split the route to extract workspaceId and viewId
            const parts = menu.subMenu_route.split('/');
            const workspaceId = parts[1];
            const viewId = parts[2];
            navigate('dashboard', {
                state: { worksapceid: workspaceId, viewid: viewId }
            });
            // navigate(`dashboard${menu.subMenu_route}`);
        } else {
            navigate(`/${menu.subMenu_route}`);
        }
        setInputValue('');
        removeSearchDetails(menu?.menu_name)
    }

    const getMenuName = (menu) => {
        if (menu.Source_Type === 1) {
            return menu.subMenu_name
        } else {
            return t(`home.${menu.subMenu_name}`)
        }
    }
    return (
        <>
            <Box sx={{ maxWidth: '1920px' }}>
                <CssBaseline />
                {/* Navigation bar */}
                <AppBar
                    component="nav"
                    sx={{
                        backgroundColor: 'white',
                        boxShadow: 'none',
                        width: '100%'
                    }}
                >
                    <Box className='box space'>
                        <FlexBetween>
                            <IconButton sx={{ ml: '10px' }} onClick={() => setIsSidebarOpen(!isSidebarOpen)} >
                                <img src={process.env.PUBLIC_URL + `/assets/images/menu.png`} width={20} height={20} />
                            </IconButton> &nbsp;&nbsp;&nbsp;
                            <IconButton className='icon background' onClick={() => { getSyncDate(); refreshPage(); }} sx={{ mr: { xs: '10px', md: '43px' } }}>
                                <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogoOld.png`} alt='logo' height={34} />
                            </IconButton>
                            {/* <Box ml={1} width='200px'>
                                <Autocomplete
                                    size="small"
                                    id="free-solo-demo"
                                    fullWidth
                                    freeSolo
                                    sx={muiStyles.mainAutocompleteStyle.root}
                                    options={searchKeywords?.map(keyword => keyword.keyword) || []}
                                    getOptionLabel={(option) => option || ''}
                                    value={inputValue ?? ''}
                                    onChange={(event, newValue) => {
                                        setInputValue(newValue ?? '');
                                        handleOptionSelect(event, newValue);
                                    }}
                                    inputValue={inputValue ?? ''}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue ?? '');
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ fontSize: '12px' }}>
                                            {option}
                                        </li>
                                    )}
                                    filterOptions={(options, state) => {
                                        console.log(state);
                                        const input = state.inputValue ? state.inputValue.toLowerCase() : '';
                                        return options?.filter(option => option.toLowerCase().startsWith(input));
                                    }}
                                    renderInput={(params) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                border: 'none',
                                                borderRadius: '8px',
                                                backgroundColor: '#F4FFFD',
                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                            }}
                                        >
                                            <TextField {...params} variant="outlined" placeholder="Search..." size="small" />
                                        </Box>
                                    )}
                                />
                            </Box> */}

                            {/* <Search>
                                <Autocomplete
                                    freeSolo
                                    options={searchKeywords.map(keyword => keyword.keyword)}
                                    openOnFocus
                                    // value={inputValue}
                                    // onChange={(event, newValue) => {
                                    //     setInputValue(newValue);
                                    //     handleOptionSelect(event, newValue);
                                    // }}
                                    getOptionLabel={(option) => option} // Define how to get the label
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ fontSize: '12px' }}>
                                            {option}
                                        </li>
                                    )}
                                    filterOptions={(options, state) => {
                                        return options.filter(option =>
                                            option.toLowerCase().startsWith(state.inputValue.toLowerCase())
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <StyledInputBase
                                            {...params.InputProps}
                                            placeholder="Search"
                                            inputProps={{ ...params.inputProps, 'aria-label': 'search', style: { color: 'black', width: '150px' } }}
                                            endAdornment={
                                                <StyledSearchIcon />
                                                // <CloseIcon onClick={()=>setInputValue('')}/>
                                            }
                                        />
                                    )}
                                    onChange={handleOptionSelect}
                                />
                            </Search> */}
                        </FlexBetween>
                        <Box >
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, }}>
                                <FlexBetween>
                                    {/* {isNonMobile && <span style={{ color: 'black', fontSize: '12px' }}>{t(`home.syncDate`)}    : {syncDate}
                                        <IconButton onClick={() => { getSyncDate(); refreshPage(); }}><RefreshIcon fontSize='small' /></IconButton>
                                    </span>} */}
                                    {/* <IconButton aria-label="show 17 new notifications" className='icon background'>
                                        <Badge badgeContent={''} sx={{ "& .MuiBadge-badge": { fontSize: 5, height: 10, minWidth: 12, bgcolor: '#489C8F', color: 'white' } }}>
                                            <NotificationsNoneIcon fontSize='small' />
                                        </Badge>
                                    </IconButton> */}
                                    {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className='icon background'>
                                        <LanguageOutlinedIcon fontSize='small' />
                                    </IconButton> */}
                                    <IconButton onClick={handleOpenSettingMenu} sx={{ p: 0, ml: '10px' }} className='icon background'>
                                        <SettingsIcon fontSize='small' />
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem sx={{ height: '50%', my: 'auto', ml: '10px', mr: '5px', borderLeft: '1px solid #DADADA' }} />
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}

                                        className='icon background'
                                    >
                                        <Avatar alt="User" src={user?.photo ? `${process.env.REACT_APP_BASE_URL}${user.photo}` : process.env.PUBLIC_URL + `/assets/images/user_avatar.png`} sx={{ height: 25, width: 25 }} />
                                    </IconButton>
                                </FlexBetween>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls='primary-search-account-menu-mobile'
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                >
                                    <Avatar alt="User" src={user?.photo ? `${process.env.REACT_APP_BASE_URL}${user.photo}` : process.env.PUBLIC_URL + `/assets/images/user_avatar.png`} sx={{ height: 25, width: 25 }} />
                                </IconButton>
                            </Box>
                        </Box>

                        {/* Menu which opens when click on User image in mobile view */}
                        <Menu
                            anchorEl={mobileMoreAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            id={0}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={isMobileMenuOpen}
                            onClose={handleMobileMenuClose}
                        >

                            <Box className='nodata-text' m={1}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{user?.firstname}</Typography>
                                <Typography style={{ fontSize: '13px' }}><i>{user?.email}</i></Typography>
                                <Typography style={{ fontSize: '13px' }}><i>{t(`home.syncDate`)}    :{syncDate}</i></Typography>
                            </Box>
                            <Divider />
                            <MenuItem key='messages' sx={{ fontSize: '14px' }}><MailIcon sx={{ mr: '10px', fontSize: '18px' }} />Messages</MenuItem>
                            <MenuItem key='notification' sx={{ fontSize: '14px' }}><NotificationsNoneIcon sx={{ mr: '10px', fontSize: '18px' }} />Notifications</MenuItem>
                            {/* <MenuItem key='language' onClick={handleOpenUserMenu} sx={{ fontSize: '14px' }}><LanguageOutlinedIcon sx={{ mr: '10px', fontSize: '18px' }} />Change Language{anchorElUser ? <ChevronLeft /> : <ChevronRight />}</MenuItem> */}
                            <MenuItem key='password' onClick={handleClickOpen} sx={{ fontSize: '14px' }}><LockResetIcon sx={{ mr: '10px', fontSize: '18px' }} /> Change Password</MenuItem>
                            <MenuItem key='logout' onClick={() => { handleLogoutConfirmation() }} sx={{ fontSize: '14px' }}><PowerSettingsNewIcon sx={{ mr: '10px', fontSize: '18px' }} /> Logout</MenuItem>
                        </Menu>

                        {/* Menu display language options */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem sx={{ fontSize: '13px' }} key={0} onClick={() => { languageChange('en') }}>
                                English
                            </MenuItem>
                            {/* <MenuItem sx={{fontSize: '13px'}} key={1} onClick={() => { languageChange('fr') }}>
                               French
                            </MenuItem> */}
                            <MenuItem sx={{ fontSize: '13px' }} key={2} onClick={() => { languageChange('ar') }}>
                                Arabic
                            </MenuItem>
                        </Menu>

                        {/* Menu which opens when click on Settings menu */}
                        <Menu
                            anchorEl={anchorElSetting}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            id={0}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElSetting)}
                            onClose={handleCloseSettingMenu}
                        >

                            <Box>
                                <span style={{ fontSize: '15px', fontWeight: 500, padding: '10px' }}>Settings</span>
                            </Box>
                            <List component="div" disablePadding sx={{ marginLeft: '20px' }}>
                                {settingsMenu?.sort((a, b) => a.subMenu_order - b.subMenu_order).map(menu => (
                                    <ListItem key={menu.subMenu_id} disablePadding onClick={() => { setActive(menu.subMenu_id); navigate(`/${menu.subMenu_route}`); handleCloseSettingMenu() }}>
                                        <ListItemButton >
                                            <ListItemText disableTypography primary={
                                                <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                                                    <img src={active === menu.subMenu_id ?
                                                        process.env.PUBLIC_URL + `/assets/images/${menu.SubMenu_Icon.split('.')[0]}_green.png`
                                                        :
                                                        process.env.PUBLIC_URL + `/assets/images/${menu.SubMenu_Icon}`}
                                                        className='menu-icon' alt="Dashboard Icon" />
                                                    <span style={{ marginLeft: '14px', color: active === menu.subMenu_id ? '#489C8F' : 'black' }}>
                                                        {/* {t(`home.${menu.subMenu_name}`)} */}
                                                        {menu.subMenu_name}
                                                        </span>
                                                </div>
                                            } />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Menu>

                        {/* Menu for logout and password change settings */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',   // Align the bottom of the menu with the bottom of the IconButton
                                horizontal: 'right',  // Align the right edge of the menu with the right edge of the IconButton
                            }}
                            transformOrigin={{
                                vertical: 'top',      // Align the top of the menu with the bottom of the IconButton
                                horizontal: 'right',  // Align the right edge of the menu with the right edge of the IconButton
                            }}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <Box m={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}><Avatar alt="User" src={user?.photo ? `${process.env.REACT_APP_BASE_URL}${user.photo}` : process.env.PUBLIC_URL + `/assets/images/user_avatar.png`} /></Grid>
                                    <Grid item xs={9}>
                                        <Box ml={1}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{user?.firstname}</Typography>
                                            <Typography style={{ fontSize: '13px' }}>{user?.email}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            <MenuItem key='password' onClick={handleClickOpen} sx={{ fontSize: '14px' }}><LockResetIcon sx={{ mr: '10px', fontSize: '18px' }} /> Change Password</MenuItem>
                            <MenuItem key='logout' onClick={() => { handleLogoutConfirmation() }} sx={{ fontSize: '14px' }}><PowerSettingsNewIcon sx={{ mr: '10px', fontSize: '18px' }} /> Logout</MenuItem>

                        </Menu>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '15px', textAlign: 'center' }}>
                                <Box sx={{ float: 'right' }}>
                                    <Tooltip title='Close'><IconButton className='icon delete' onClick={handleClose}><CloseIcon /></IconButton></Tooltip>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <ChangePassword handleClose={handleClose} />
                            </DialogContent>
                        </Dialog>
                    </Box>
                </AppBar>

                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
                    {/* Side Navigation bar */}
                    <Box component="nav" >
                        {isSidebarOpen && (
                            <Drawer
                                open={isSidebarOpen}
                                onClose={drawerClose}
                                variant="permanent"
                                anchor={languageDirection === 'rtl' ? 'right' : 'left'}
                                transitionDuration={500}
                                sx={{
                                    width: drawerWidth,
                                    height: '100%',
                                    position: !isNonMobile ? 'absolute' : '',
                                    // pointerEvents: 'auto',
                                    "& .MuiDrawer-paper": {
                                        boxSixing: "border-box",
                                        borderWidth: isNonMobile ? 0 : "2px",
                                        width: drawerWidth,
                                        height: isNonMobile ? `calc(100% - 55px)` : '100%',
                                        // boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.1)",
                                        marginTop: isNonMobile ? '48px' : 0,
                                    },
                                }}
                            >
                                <Box width="100%">
                                    {/* <Box
                                    m="1.5rem 0rem 1.5rem 0rem"
                                    display='flex'
                                    justifyContent='center'
                                > <Link to='https://www.rcor.co/'><img src={newLogo} alt='logo' /></Link></Box> */}
                                    {!isNonMobile && (
                                        <>
                                            <IconButton onClick={() => setIsSidebarOpen(false)}>
                                                <ChevronLeft />
                                            </IconButton>
                                        </>
                                    )}
                                    <List>
                                        {mainMenu?.filter(menu => menu.menu_name !== 'Settings').map(item => (
                                            <React.Fragment key={item.menu_id}>
                                                <ListItem disablePadding key={item.menu_id}>
                                                    <ListItemText disableTypography sx={{ fontWeight: '500', fontSize: '16px', ml: '18px', mb: '10px' }} primary={
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span >{t(`home.${item.menu_name}`)}</span>
                                                        </div>
                                                    } />
                                                </ListItem>
                                                <Collapse key={item.subMenu_id} in={true} timeout="auto" unmountOnExit>
                                                    <List key={item.menu_order} component="div" disablePadding sx={{ marginLeft: '20px', mb: '10px' }}>
                                                        {menuArray?.filter(element => element.menu_order === item.menu_order).sort((a, b) => a.subMenu_order - b.subMenu_order).map(menu => (
                                                            <ListItem key={menu.subMenu_id} disablePadding onClick={() => onMenuClick(menu)}>
                                                                <ListItemButton sx={{ borderLeft: '2px solid #DADADA' }}>
                                                                    {/* Add small line overlaying the border */}
                                                                    {active === menu.subMenu_id && (
                                                                        <div className='div active-menu' />
                                                                    )}
                                                                    <ListItemText disableTypography primary={
                                                                        // <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        //     {getMenuIcon(menu.SubMenu_Icon, active === menu.subMenu_route ? '#489C8F' : '#303030')}
                                                                        //     <span style={{ marginLeft: '10px', color: active === menu.subMenu_route ? '#489C8F' : 'black', fontSize: '14px' }}>{getMenuName(menu)}</span>
                                                                        // </div>
                                                                        <Grid container columnSpacing={1}>
                                                                            <Grid item xs={2} display='flex' alignItems='center'>
                                                                                {getMenuIcon(menu.SubMenu_Icon, active === menu.subMenu_id ? '#489C8F' : '#5f6368', "24px")}
                                                                            </Grid>
                                                                            <Grid item xs={10} display='flex' alignItems='center'>
                                                                                <span style={{ color: active === menu.subMenu_id ? '#489C8F' : 'black', fontSize: '14px' }}>{getMenuName(menu)}</span>
                                                                            </Grid>
                                                                        </Grid>
                                                                    } />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Collapse>

                                            </React.Fragment>
                                        ))}
                                    </List>
                                </Box>
                            </Drawer>
                        )
                        }
                        {!isSidebarOpen && isNonMobile && <IconDrawer mainMenu={mainMenu} menuArray={menuArray} active={active} setActive={setActive} isSidebarOpen={isSidebarOpen} languageDirection={languageDirection} />}
                    </Box>

                    {/* Main content */}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            // p: 2,
                            pl: 2, pr: 2, pb: 2,
                            mt: '48px',
                            width: isNonMobile ? `calc(100% - ${drawerWidth}px)` : '100%',
                            // ml: isNonMobile? `${drawerWidth}px`: '0px',
                            borderTopLeftRadius: '15px'
                        }}
                    >
                        <InternetStatusChecker><Outlet isSidebarOpen={isSidebarOpen} mainDrawerwidth={`${drawerWidth}px`} iconDrawerwidth='50px' /></InternetStatusChecker>
                        {/* bottombar */}
                        {/* <Box >
                            <AppBar position='fixed' className='appbar-bottom chat' sx={{ width: isSidebarOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 50px)` }}>
                                <Toolbar > */}
                        {/* Left side icon */}
                        {/* <IconButton color="#212121" aria-label="menu" onClick={handleLeftPopupOpen}  >
                                        <Badge
                                            color="success" // Set the color for the badge (green)
                                            overlap="circular" // Make the badge circular
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            variant="dot" // Display a small dot as the badge
                                        >
                                            <PersonIcon className='message-icon chat' />
                                        </Badge>
                                    </IconButton>
                                    <Typography variant="body2" color="textPrimary" sx={{ marginBottom: '40px' }}>
                                        Who's Online (4)
                                    </Typography> */}
                        {/* Right side icon */}
                        {/* <Box sx={{ flexGrow: 1 }} />

                                    <IconButton color="#212121" aria-label="settings" onClick={handleChatOpen}>
                                        <RedditIcon className='reddit-icon chat' />
                                    </IconButton> */}

                        {/* Left Popup */}
                        {/* <Modal
                                        open={isLeftPopupOpen}
                                        onClose={handleLeftPopupClose}
                                        aria-labelledby="left-popup"
                                        aria-describedby="left-popup-description"
                                        className='left-modal chat'>
                                        <Box><LeftChat /></Box>
                                    </Modal> */}

                        {/* Right Dialog */}
                        {/* <Dialog open={isChatOpen} onClose={handleChatClose} PaperProps={{ sx: muiStyles.dialogPaperProps.root, }}>
                                        <DialogTitle className='dialog-title chat' >Chat with us</DialogTitle>
                                        <DialogContent>
                                            <p>This is where the chat bot content will appear.</p>
                                        </DialogContent>
                                        <RightChat />
                                    </Dialog>
                                    <Typography variant="body2" color="textPrimary" sx={{ marginBottom: '40px' }}>
                                        Chat with our experts
                                    </Typography>
                                </Toolbar> */}

                        {/* Navbar content */}
                        {/* </AppBar>
                        </Box> */}

                    </Box>

                </Box>
                <Box className='scrollToTopButton box'>
                    <ScrollToTopButton />
                </Box>

            </Box>
        </>
    );
}


export default DrawerComponent;