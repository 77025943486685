import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Header from '../CustomerOnboarding/Header';
import { Link, useNavigate } from 'react-router-dom';
import { getUserMenu } from '../api/userApi';
import { useSelector } from 'react-redux';
import DatasourceHeader from '../datasource/DatasourceHeader';

function NoWorkspace() {
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const [hasWorkspaces, setHasWorkspaces] = useState(true); // Tracks if dashboards exist

    useEffect(() => {
        if (user) {
            getMenu(user.usertypeid);
        }
    }, [user]);

    const getRoute = (menu) => {
        if (menu.Source_Type === 1) {            
            // Split the route to extract workspaceId and viewId
            const parts = menu.subMenu_route.split('/');
            const workspaceId = parts[1];
            const viewId = parts[2];
            navigate('/dashboard', {
                state: { worksapceid: workspaceId, viewid: viewId },
            });
        } else {
            // window.location.href = `/${menu.subMenu_route}`;
            // window.location.href = '/no_workspace'
            setHasWorkspaces(false);
        }
    };

    const getMenu = async (userTypeId) => {
        console.log('inside menu');
        try {
            const response = await getUserMenu(userTypeId);
            console.log('menu', response);
            if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
                const dashboards = response.data.SubMenu.filter(
                    item => item.menu_name === 'DashBoards'
                );

                if (dashboards.length > 0) {
                    // Find the dashboard with the smallest `subMenu_order`
                    const leastOrderDashboard = dashboards.reduce((min, item) =>
                        item.subMenu_order < min.subMenu_order ? item : min
                    );

                    // Redirect to the route of the dashboard with the least `subMenu_order`
                    getRoute(leastOrderDashboard);
                } else {
                    setHasWorkspaces(false); // No dashboards found
                }
            } else {
                setHasWorkspaces(false); // No dashboards found
            }
        } catch (error) {
            console.log(error);
            setHasWorkspaces(false); // Error fetching menu, assume no dashboards
        }
    };

    return (
        <Box>
            <DatasourceHeader />
            {hasWorkspaces ? null : (
                <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} display="flex" justifyContent="center">
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/onboarding_img.png'}
                                    alt="not found"
                                    className="image common"
                                    height={250}
                                    width={250}
                                />
                                <Typography mt={3} mb={1} className='datasource datasource-heading'>
                                    RCOR is setting up your workspace...
                                </Typography>
                                <Typography mt={2} mb={2} className='datasource datasource-subtitle'>
                                    Your personalized analytics workspace is being prepared. We'll notify you via email once it's ready.
                                    {/* <Link href="mailto:contact@rcor.co">contact@rcor.co</Link> */}
                                </Typography>
                                {/* <Button onClick={() => navigate('/onBoarding_datasource')} className='datasource datasource-button'>Get Started &nbsp;<EastIcon /></Button> */}
                            </Box>
                            {/* <Box display="flex" flexDirection="column" alignItems="center">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/onboarding_img.png'}
                                    alt="not found"
                                    className="image common"
                                />
                                <Typography variant="h4" mb={1}>
                                    RCOR is setting up your workspace...
                                </Typography>
                                <Typography>
                                    Your personalized analytics workspace is being prepared. We'll notify you via email once it's ready.
                                </Typography>
                            </Box> */}
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>
            )}
        </Box>

    );
}

export default NoWorkspace;
