import React from 'react'
import { Box, Alert, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../redux/slices/authSlice';
import { logout } from '../api/userApi';

function Logout() {
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
    const dispatch = useDispatch();

    const serverLogout = async (id) => {
      console.log(id);
      try {
          const response = await logout(id)
          console.log(response);
      } catch (error) {
          console.log(error);
      }
  }

    const logOut = () => {
      serverLogout(user?.userid)
        localStorage.removeItem("token")
        localStorage.clear()
        dispatch(setLogout())
        window.location.href = '/loginpage'
    }

    return (
        // <Box>
        //     <Grid container spacing={1}>
        //         <Grid item xs={4}></Grid>
        //         <Grid item xs={4} mt={4}>
        //             <Alert icon={false} severity="error" >Session Expired!!!!! <Button sx={{textTransform: 'none'}} size='small' onClick={() => logOut()}>Please Login</Button></Alert>
        //         </Grid>
        //         <Grid item xs={4}></Grid>
        //     </Grid>
        // </Box>
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Grid container spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} display='flex' justifyContent='center' >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img src={process.env.PUBLIC_URL + '/assets/images/session img.png'} alt='not found' className='image common'/>
              <Typography variant='h5'>Your session has expired!!!</Typography><br></br>
              <Button className="btn btn-search"  onClick={() => logOut()}>Please Login</Button>
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Box>
        // <Box>
        //     <Grid container spacing={1}>
        //         <Grid item xs={4}></Grid>
        //         <Grid item xs={4} display='flex' justifyContent='center' >
        //             <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={5}>
        //                 {/* <img src={ExpiredImage} /> */}
        //                 {/* <Typography variant='h5'>Your session has expired.</Typography> */}
        //                 <Alert  severity="error" ><Typography variant='h5'>Your session has expired.</Typography></Alert>
        //                 <Button sx={{textTransform: 'none', mt:1}} variant='contained' size='small' onClick={() => logOut()}>Please Login</Button>
        //             </Box>
        //         </Grid>
        //         <Grid item xs={4}></Grid>
        //     </Grid>
        // </Box>
    )
}

export default Logout